import {
  CHANGE_LANGUAGE
} from "../actions/types";

import { languageOptions, dictionaryList } from '../../languages/Options';

const initialState = {
  language: languageOptions[0],
  dictionary: dictionaryList[languageOptions[0].id]
};

// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  switch (action.type) {
    case CHANGE_LANGUAGE:
      return {
        ...state,
        language: action.language,
        dictionary: dictionaryList[action.language.id]
      };
    default:
      return state;
  }
};