/* eslint-disable class-methods-use-this */
import React from 'react';
import { Row, Col, Tab, Nav } from 'react-bootstrap';

import Aux from "../../hoc/_Aux/Aux";
import '../../styles/scss/style.scss';
// import Breadcrumb from "../../components/Breadcrumb";
import Text from "../../components/Text";

import PrivacyPolicyEng from "./PrivacyPolicyEng";
import PrivacyPolicySpa from "./PrivacyPolicySpa";

class PrivacyPolicy extends React.Component {
  render() {
    return (
      <Aux>
        {/* <Breadcrumb /> */}
        <Row>
          <Col>
            <div className="card-body">
              <h5 className="mt-4">
                <Text tid="privacyPolicy" />
              </h5>
              <hr />
              <Tab.Container defaultActiveKey="eng">
                <Row>
                  <Col sm={3}>
                    <Nav variant="pills" className="flex-column">
                      <Nav.Item>
                        <Nav.Link eventKey="eng">English</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="spa">Spanish</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col sm={9}>
                    <Tab.Content>
                      <Tab.Pane eventKey="eng">
                        <PrivacyPolicyEng />
                      </Tab.Pane>
                      <Tab.Pane eventKey="spa">
                        <PrivacyPolicySpa />
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>


            </div>
          </Col>
        </Row>
      </Aux>
    );
  }
}

export default PrivacyPolicy;