/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable no-restricted-syntax */
import React, { Component } from "react";
import moment from "moment";
import c from "./SingleBlog.module.css";
import Loading from "../../components/Loading";
import NavLanding from "../../components/NavLanding"

import postService from "../../services/postService";
import Aux from "../../hoc/_Aux/Aux";
import { Styles } from "../landing/LandingStyles";

class SingleBlog extends Component {
  state = {
    singlePost: {},
    titleid: this.props.location.pathname,
    avatar: "",
    profileLink: "",
    error: null,
    isloading: true
  };

  async componentDidMount() {
    await postService.getMediumFeed()
      .then((data) => {
        const avatar = data.feed.image;
        const profileLink = data.feed.link;

        const titleSlice = this.state.titleid.slice(5, this.state.titleid.length);

        // eslint-disable-next-line guard-for-in
        for (const i in data.items) {
          const title = `/${data.items[i].guid.slice(21, data.items[i].guid.length)}`;

          if (title === titleSlice) {
            const post = data.items[i];
            this.setState(() => ({
              singlePost: post,
              avatar,
              profileLink,
              isloading: false
            }));
          }
        }

      })
      .catch((e) => {
        this.setState({ error: e.toJSON() })
        console.log(e);
      });
  }

  render() {
    let post
    if (this.state.singlePost) {
      post = (<>
        <h2>{this.state.singlePost.title}</h2>
        <div className={c.avatar}>
          <a
            href={this.state.profileLink}
            rel="noopener noreferrer"
            target="_blank"
          >
            <img src={this.state.avatar} alt="profile" width="75" height="75" />
          </a>

          <a
            href={this.state.profileLink}
            rel="noopener noreferrer"
            target="_blank"
          >
            <p>{this.state.singlePost.author}</p>
          </a>
          <p>{moment(this.state.singlePost.pubDate).format("DD MMMM, YYYY HH:mm")}</p>
        </div>

        <div className={c.content} dangerouslySetInnerHTML={{ __html: this.state.singlePost.content }}>
        </div>
      </>
      )
    }
    if (this.state.isloading) {
      post = <Loading />
    }
    if (this.state.error) {
      const error = this.state.error.code ? this.state.error.code : this.state.error.name;
      const errorMsg = this.state.error.message;
      post = (
        <>
          <h2 className="red center1">{error}</h2>
          <p className="errorMessage center1">{errorMsg}</p>
        </>
      );
    }

    return (
      <Aux>
        <Styles>
          <div className="card-body">
            <div className={`container ${c.center}`}>
              <div
                style={{ paddingTop: '100px' }}
              >
                <NavLanding />
                <div>
                  {post}
                </div>
              </div>
            </div>

          </div>
        </Styles>
      </Aux>
    );
  }
}

export default SingleBlog;