import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { languageOptions } from "../languages/Options";

import { onChangeLanguage } from "../store/actions/languageActions";

class LanguageSelector extends Component {
  handleLanguageChange(e) {
    e.preventDefault();
    const selectedLanguage = languageOptions.find(
      (item) => item.id === e.target.value
    );

    this.props.onChangeLanguage(selectedLanguage);
  }

  render() {
    const { language } = this.props.lang;
    return (
      <select
        className="language-options"
        onChange={this.handleLanguageChange.bind(this)}
        value={language.id}
      >
        {languageOptions.map((item) => (
          <option key={item.id} value={item.id}>
            {item.text}
          </option>
        ))}
      </select>
    );
  }
}

LanguageSelector.propTypes = {
  onChangeLanguage: PropTypes.func.isRequired,
  lang: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  lang: state.lang,
  auth: state.auth,
});

export default connect(mapStateToProps, { onChangeLanguage })(LanguageSelector);
