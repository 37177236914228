const daysOfTheWeek = [
  {
    id: 1,
    day: 'monday',
  },
  {
    id: 2,
    day: 'tuesday',
  },
  {
    id: 3,
    day: 'wednesday',
  },
  {
    id: 4,
    day: 'thursday',
  },
  {
    id: 5,
    day: 'friday',
  },
  {
    id: 6,
    day: 'saturday',
  },
  {
    id: 7,
    day: 'sunday',
  },
];

const musicians = [
  {
    id: 1,
    type: 'dj',
  },
  {
    id: 2,
    type: 'singer',
  },
  {
    id: 3,
    type: 'orchestra',
  },
  {
    id: 4,
    type: 'bandoneonist',
  },
  {
    id: 5,
    type: 'guitarist',
  },
  {
    id: 6,
    type: 'composer',
  },
  {
    id: 7,
    type: 'quartet',
  },
  {
    id: 8,
    type: 'band',
  },
];

const danceCategories = [
  {
    id: 1,
    type: 'venue',
  },
  {
    id: 2,
    type: 'practica',
  },
  {
    id: 3,
    type: 'class',
  },
  {
    id: 4,
    type: 'school',
  },
  {
    id: 5,
    type: 'teacher',
  },
  {
    id: 6,
    type: 'radio',
  },
  {
    id: 7,
    type: 'musician',
  },
];

const frequencyOptions = [
  {
    id: 1,
    type: 'once',
  },
  {
    id: 2,
    type: 'weekly',
  },
  {
    id: 3,
    type: 'fortnightly',
  },
  {
    id: 4,
    type: 'monthly',
  },
];

module.exports = {
  daysOfTheWeek,
  danceCategories,
  frequencyOptions,
  musicians,
};