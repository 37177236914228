/* eslint-disable consistent-return */
/* eslint-disable no-restricted-globals */
// import axios from "axios";
// eslint-disable-next-line camelcase
import jwt_decode from "jwt-decode";
// import { createBrowserHistory } from "history";
import { toast } from "react-toastify";
import setAuthToken from "../utils/setAuthToken";
import authService from "../../services/authService";

import {
  SET_CURRENT_USER,
  CLEAR_CURRENT_USER,
  GET_ERRORS,
  CLEAR_ERRORS,
} from "./types";

// register user
export const registerUser = (userData) => (dispatch) => {
  authService
    .signup(userData)
    .then((res) => {
      // .then(() => {
      // redirect user to login page
      if (res.success === true) {
        // window.location.href = "/login"
        // history.push("/login");
        // createBrowserHistory().goBack()
        toast.success("🚀 You are registered now");
        toast.info(" 📩 We sent you an email");
      }
      // clear errors
      dispatch({
        type: CLEAR_ERRORS,
        payload: {},
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
        // payload: err,
      });
    });
};

// set token of logged in user
export const setCurrentUser = (decoded) => ({
  type: SET_CURRENT_USER,
  payload: decoded,
});

// login user
// export const loginUser = (userData, history) => (dispatch) => {
export const loginUser = (userData) => (dispatch) => {
  authService
    .login(userData)
    .then((res) => {
      // save to localStorage
      // const { token } = res.data;
      // const { token } = res;
      // set token to localStorage
      localStorage.setItem("jwtToken", res.token);
      // set token to auth header
      setAuthToken(localStorage.jwtToken);
      // decode token to get user data

      const decoded = jwt_decode(localStorage.jwtToken);

      //   set current user
      // eslint-disable-next-line no-use-before-define

      dispatch(setCurrentUser(decoded));

      // redirect user

      // history.replace({ state: token });

      // setTimeout(() => {

      //   if (decoded.role === 'admin') {
      //     // history.push("/dashboard/admin/bailo-dots");

      //     history.push({
      //       pathname: '/dashboard/admin/bailo-dots',
      //       state: token,
      //     });
      //     // this.setState({isLogin: true});

      //   } else {
      //     history.push("/dashboard/map");
      //   }

      //   // this.context.router.history.push({
      //   // pathname:'/',
      //   // });
      //   // this.setState({isLogin: true});
      // }, 500);

      toast.success("🦄 Welcome to Bailo.app!");

      // if (decoded.role === "admin") {
      //   history.push("/dashboard/admin/bailo-dots");
      //   // history.push({
      //   //   pathname: '/dashboard/admin/bailo-dots',
      //   //   state: token,
      //   // });
      //   // this.setState({isLogin: true});
      // } else {
      //   history.push("/dashboard/map");
      // }

      // clear errors
      dispatch({
        type: CLEAR_ERRORS,
        payload: {},
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
        // payload: err,
      })
    );
};

export const googleRegisterUser = (tokenId) => (dispatch) =>
  authService
    .googleSignup(tokenId)
    .then((res) => {
      if (res.success === true) {
        toast.success("🚀 You are registered now - Please WAIT ");
        toast.info("⏳ Please WAIT...");
        authService
          .googleLogin(tokenId)
          .then((res_) => {
            localStorage.setItem("jwtToken", res_.token);
            setAuthToken(localStorage.jwtToken);

            // decode token to get user data
            const decoded = jwt_decode(localStorage.jwtToken);

            dispatch(setCurrentUser(decoded));

            toast.success("🦄 Welcome to Bailo.app!");

            // clear errors
            dispatch({
              type: CLEAR_ERRORS,
              payload: {},
            });
          })
          .catch((err) =>
            dispatch({
              type: GET_ERRORS,
              payload: err.response.data,
            }).then(() => {
              toast.error(`😡 ${err.response.data.messageError}`);
            })
          );
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      toast.error(`😡 ${err.response.data.messageError}`);
    });

export const googleLoginUser = (tokenId) => (dispatch) => {
  authService
    .googleLogin(tokenId)
    .then((res) => {
      localStorage.setItem("jwtToken", res.token);
      setAuthToken(localStorage.jwtToken);

      // decode token to get user data
      const decoded = jwt_decode(localStorage.jwtToken);

      dispatch(setCurrentUser(decoded));

      toast.success("🦄 Welcome to Bailo.app!");

      // clear errors
      dispatch({
        type: CLEAR_ERRORS,
        payload: {},
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      toast.error(`😡 ${err.response.data.messageError}`);
    });
};

export const facebookLoginUser = (tokenId) => (dispatch) => {
  authService
    .facebookLogin(tokenId)
    .then((res) => {
      localStorage.setItem("jwtToken", res.token);
      setAuthToken(localStorage.jwtToken);

      // decode token to get user data
      const decoded = jwt_decode(localStorage.jwtToken);

      dispatch(setCurrentUser(decoded));

      toast.success("🦄 Welcome to Bailo.app!");

      // clear errors
      dispatch({
        type: CLEAR_ERRORS,
        payload: {},
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      toast.error(`😡 ${err.response.data}`);
    });
};

export const facebookRegisterUser = (tokenId) => (dispatch) =>
  authService
    .facebookSignup(tokenId)
    .then((res) => {
      if (res.success === true) {
        toast.success("🚀 You are registered now - Please WAIT ");
        toast.info("⏳ Please WAIT...");
        authService
          .facebookLogin(tokenId)
          .then((res_) => {
            localStorage.setItem("jwtToken", res_.token);
            setAuthToken(localStorage.jwtToken);

            // decode token to get user data
            const decoded = jwt_decode(localStorage.jwtToken);

            dispatch(setCurrentUser(decoded));

            toast.success("🦄 Welcome to Bailo.app!");

            // clear errors
            dispatch({
              type: CLEAR_ERRORS,
              payload: {},
            });
          })
          .catch((err) =>
            dispatch({
              type: GET_ERRORS,
              payload: err.response.data,
            }).then(() => {
              toast.error(`😡 ${err.response.data}`);
            })
          );
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      toast.error(`😡 ${err.response.data.messageError}`);
    });

// log user out
export const logoutUser = () => (dispatch) => {
  // remove token from localStorage
  localStorage.removeItem("jwtToken");
  // Clear the current user
  dispatch({
    type: CLEAR_CURRENT_USER,
    payload: {},
  });
};
