/* eslint-disable no-nested-ternary */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import { PropTypes } from "prop-types";

// eslint-disable-next-line react/prop-types
const AnonRoute = ({ component: Comp, auth, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      auth.isLoggedIn === false
        ? (
          <Comp {...props} />
        ) : auth.user.role === 'admin' ?
          (
            <Redirect
              to={
                {
                  pathname: '/dashboard/admin/bailo-dots',
                  // eslint-disable-next-line react/prop-types
                  state: { from: props.location },
                }
              }
            />
          ) :
          (
            <Redirect
              to={
                {
                  pathname: '/dashboard/map',
                  // state: { from: props.location },
                }
              }
            />
          )
      // (
      //   <Redirect
      //     to={{
      //       pathname: '/dashboard/map',
      //       // state: { from: props.location },
      //     }}
      //   />
      // )
    }
  />
);

AnonRoute.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(AnonRoute);