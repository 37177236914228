/* eslint-disable no-console */
/* eslint-disable class-methods-use-this */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Modal,
  Button,
  Table,
  // OverlayTrigger,
  // Tooltip,
  // Container,
  SplitButton,
  Dropdown,
} from "react-bootstrap";
import moment from "moment";

import MarkerClusterGroup from "react-leaflet-markercluster";
import { Map, Marker, TileLayer, Popup } from "react-leaflet";
import { Icon } from "leaflet";

import styled from "styled-components";
import Text from "../../components/Text";
import Aux from "../../hoc/_Aux/Aux";
// import Breadcrumb from "../../components/Breadcrumb";

import mapdotService from "../../services/mapdotService";
import cityService from "../../services/cityService";
import postService from "../../services/postService";

import BannerMap from "./components/BannerMap";

import Loading from "../../components/Loading";
// import Pagination from "../admin/components/Pagination";

require("react-leaflet-markercluster/dist/styles.min.css"); // inside .js file

const { daysOfTheWeek, danceCategories } = require("../../helpers/arrays");

// NOTE: Remember to add map styles .markercluster-map { height: 90vh; }.

const ContentMap = styled.div`
  /* position: relative;
  margin-top: 1em; */

  .markercluster-map {
    /* padding-bottom: 50%;
    padding-top: 30px; */
    /* height: 16em; */
    height: 91vh;
    z-index: 1;
    /* width: 100%;
    border-radius: 15px;

    @media (min-width: 768px) {
      height: 0;
    } */
  }

  /* .leaflet-control-container {
    display: none;
  } */
`;

class MainMap extends Component {
  state = {
    // eslint-disable-next-line react/prop-types
    searchWidth: this.props.windowWidth < 992 ? 90 : 0,
    // eslint-disable-next-line react/prop-types
    searchString: this.props.windowWidth < 992 ? "90px" : "",
    // eslint-disable-next-line react/prop-types
    isOpen: this.props.windowWidth < 992,
    searchBy: "venue",
    filterString: "",
    dayOfWeek: "",
    mapdotsTodaySearchBy: [],
    citiesTodaySearchBy: [],
    clickedCityId: "",
    centerLatitude: 45.469149,
    centerLongitude: 9.181158,
    zoomMap: 3,
    visibilityForm: false,
    visibilityFormName: false,
    loading: true,
    isBasic: false,
    isVertically: false,
    isTooltip: false,
    isGrid: false,
    isScrolling: false,
    isLarge: false,
    isModalName: false,
    // title: ''
    filterStringName: "",
    mapdotsPerPage: 20,
    currentPage: 1,
    currentMapdots: [],
    totalMapdots: undefined,
    filteredDate: undefined,
    radioItems: {},
  };

  async componentDidMount() {
    const { searchBy } = this.state;
    // const { currentPage, mapdotsPerPage, filterStringName } = this.state;
    const { filterStringName } = this.state;

    try {
      const momentDate = moment();
      const filteredDay = await momentDate.format("dddd").toLowerCase();
      const filteredDate = await momentDate.format("YYYY-MM-DD");

      const getMapdots = await this.loadMapdots(
        filteredDay.toLowerCase(),
        searchBy,
        filteredDate
      );
      const filteredCities = await this.loadCities(getMapdots);

      const getCurrentMapdots = await this.loadCurrentMapdots(
        filterStringName
        // mapdotsPerPage,
        // currentPage,
      );

      this.getRadioFromSpotify("raul mamone");

      this.setState({
        dayOfWeek: filteredDay,
        filteredDate,
        mapdotsTodaySearchBy: getMapdots,
        citiesTodaySearchBy: filteredCities,
        loading: false,
        currentMapdots: getCurrentMapdots,
        totalMapdots: getCurrentMapdots.length,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  }

  async loadMapdots(day, category, filteredDate) {
    try {
      return await mapdotService.searchMapdots(day, category, filteredDate);
    } catch (error) {
      return error;
    }
  }

  async loadMapdotsNoDay(category) {
    try {
      return await mapdotService.searchMapdotsNoDay(category);
    } catch (error) {
      return error;
    }
  }

  async loadCurrentMapdots(
    filterStringName
    // mapdotsPerPage,
    // currentPage,
  ) {
    try {
      return await mapdotService.getPublicBailoDots(
        filterStringName
        // mapdotsPerPage,
        // currentPage,
      );
    } catch (error) {
      return error;
    }
  }

  // paginate = async (pageNumber) => {
  //   const { filterStringName, mapdotsPerPage } = this.state;

  //   const getCurrentNewMapdots = await this.loadCurrentMapdots(
  //     filterStringName,
  //     mapdotsPerPage,
  //     pageNumber,
  //   );

  //   this.setState({
  //     currentPage: pageNumber,
  //     totalMapdots: getCurrentNewMapdots.length,
  //     currentMapdots: getCurrentNewMapdots,
  //   });
  // };

  handleChangeFilter = async (event) => {
    // if (event.target.value.length === 1) {
    if (event.target.value.length >= 1) {
      // const { mapdotsPerPage, currentPage } = this.state;

      const filterStringName = event.target.value;

      const updatedCurrentMadots = await this.loadCurrentMapdots(
        filterStringName
        // mapdotsPerPage,
        // currentPage,
      );

      // this.setState({
      //   filterStringName,
      //   totalMapdots: updatedCurrentMadots.length,
      //   currentMapdots: updatedCurrentMadots,
      // });

      this.setState({
        visibilityFormName: true,
        filterStringName,
        totalMapdots: updatedCurrentMadots.length,
        currentMapdots: updatedCurrentMadots,
      });
    } else if (event.target.value.length === 0) {
      this.setState({
        visibilityFormName: false,
        centerLatitude: 45.469149,
        centerLongitude: 9.181158,
        zoomMap: 3,
      });
    }
    await this.setState({
      filterStringName: event.target.value,
    });
  };

  async loadCities(getMapdots) {
    try {
      const filteredCityIds = await getMapdots
        .map((oneMapdot) => oneMapdot.cityObject.toString())
        .reduce((accumulator, currentValue) => {
          if (accumulator.indexOf(currentValue) === -1) {
            accumulator.push(currentValue);
          }
          return accumulator;
        }, []);

      return await cityService.searchCities(filteredCityIds);
    } catch (error) {
      return error;
    }
  }

  async getDayNameFromDate(date) {
    try {
      const d = new Date(date);
      let dayName;
      if (d.getDay() === 0) {
        dayName = daysOfTheWeek[6].day;
      } else if (d.getDay() === 1) {
        dayName = daysOfTheWeek[0].day;
      } else if (d.getDay() === 2) {
        dayName = daysOfTheWeek[1].day;
      } else if (d.getDay() === 3) {
        dayName = daysOfTheWeek[2].day;
      } else if (d.getDay() === 4) {
        dayName = daysOfTheWeek[3].day;
      } else if (d.getDay() === 5) {
        dayName = daysOfTheWeek[4].day;
      } else {
        dayName = daysOfTheWeek[5].day;
      }

      return dayName;
    } catch (error) {
      return error;
    }
  }

  async getDateFromDayName(dayName) {
    try {
      const shortDay = dayName.slice(0, 3);
      let date;

      const now = moment();
      const nowPlus1 = moment().add(1, "days");
      const nowPlus2 = moment().add(2, "days");
      const nowPlus3 = moment().add(3, "days");
      const nowPlus4 = moment().add(4, "days");
      const nowPlus5 = moment().add(5, "days");
      const nowPlus6 = moment().add(6, "days");

      if (now._d.toString().slice(0, 3).toLowerCase() === shortDay) {
        date = now.format("YYYY-MM-DD");
      } else if (
        nowPlus1._d.toString().slice(0, 3).toLowerCase() === shortDay
      ) {
        date = nowPlus1.format("YYYY-MM-DD");
      } else if (
        nowPlus2._d.toString().slice(0, 3).toLowerCase() === shortDay
      ) {
        date = nowPlus2.format("YYYY-MM-DD");
      } else if (
        nowPlus3._d.toString().slice(0, 3).toLowerCase() === shortDay
      ) {
        date = nowPlus3.format("YYYY-MM-DD");
      } else if (
        nowPlus4._d.toString().slice(0, 3).toLowerCase() === shortDay
      ) {
        date = nowPlus4.format("YYYY-MM-DD");
      } else if (
        nowPlus5._d.toString().slice(0, 3).toLowerCase() === shortDay
      ) {
        date = nowPlus5.format("YYYY-MM-DD");
      } else {
        date = nowPlus6.format("YYYY-MM-DD");
      }

      return date;
    } catch (error) {
      return error;
    }
  }

  handleChangeSearchBy = async (event) => {
    const { dayOfWeek, filteredDate } = this.state;

    let updatedMadots;
    const searchBy = event;

    this.setState({
      loading: true,
    });

    if (
      searchBy === "venue" ||
      searchBy === "practica" ||
      searchBy === "class"
    ) {
      updatedMadots = await this.loadMapdots(dayOfWeek, searchBy, filteredDate);
    } else {
      updatedMadots = await this.loadMapdotsNoDay(searchBy);

      const momentDate = moment();
      const filteredDay = await momentDate.format("dddd").toLowerCase();
      const getFilteredDate = await momentDate.format("YYYY-MM-DD");

      this.setState({
        dayOfWeek: filteredDay,
        filteredDate: getFilteredDate,
      });
    }

    const updatedCities = await this.loadCities(updatedMadots);

    this.setState({
      searchBy,
      mapdotsTodaySearchBy: updatedMadots,
      citiesTodaySearchBy: updatedCities,
      centerLatitude: 45.469149,
      centerLongitude: 9.181158,
      zoomMap: 3,
      loading: false,
    });
  };

  handleChangeDate = async (event) => {
    const { searchBy } = this.state;

    let updatedMadots;
    const filteredDate = event.target.value;
    const dayOfWeek = await this.getDayNameFromDate(event.target.value);

    this.setState({
      loading: true,
    });

    if (
      searchBy === "venue" ||
      searchBy === "practica" ||
      searchBy === "class"
    ) {
      updatedMadots = await this.loadMapdots(dayOfWeek, searchBy, filteredDate);
    } else {
      updatedMadots = await this.loadMapdotsNoDay(searchBy);
    }

    const updatedCities = await this.loadCities(updatedMadots);

    this.setState({
      filteredDate,
      dayOfWeek,
      mapdotsTodaySearchBy: updatedMadots,
      citiesTodaySearchBy: updatedCities,
      centerLatitude: 45.469149,
      centerLongitude: 9.181158,
      zoomMap: 3,
      loading: false,
    });
  };

  handleChangeCities = async (event) => {
    if (event.target.value.length === 1) {
      this.setState({
        visibilityForm: true,
      });
    } else if (event.target.value.length === 0) {
      this.setState({
        visibilityForm: false,
        centerLatitude: 45.469149,
        centerLongitude: 9.181158,
        zoomMap: 3,
      });
    }
    await this.setState({
      filterString: event.target.value,
    });
  };

  async handleClickCity(city) {
    const { cityName } = city;
    try {
      await postService.sendCity(cityName).catch((error) => {
        console.log(error);
      });
    } catch (error) {
      console.log(error);
    }

    this.setState({
      centerLatitude: city.coordinatesLatLongCity.Latitude,
      centerLongitude: city.coordinatesLatLongCity.Longitude,
      zoomMap: 10,
      isLarge: false,
      visibilityForm: false,
    });
  }

  async handleClickMapdot(mapdot) {
    const searchBy = mapdot.categoryIdName;
    let dayOfWeek = "";
    let filteredDate = "";
    let updatedMadots;

    this.setState({
      loading: true,
    });

    if (
      searchBy === "venue" ||
      searchBy === "practica" ||
      searchBy === "class"
    ) {
      dayOfWeek = mapdot.day;

      if (mapdot.dateOnce !== "") {
        filteredDate = mapdot.dateOnce;
      } else if (
        mapdot.dateStart !== "" &&
        mapdot.dateEnd !== "" &&
        searchBy === "class"
      ) {
        filteredDate = mapdot.dateStart;
      } else {
        filteredDate = await this.getDateFromDayName(dayOfWeek);
      }

      updatedMadots = await this.loadMapdots(dayOfWeek, searchBy, filteredDate);
    } else {
      const momentDate = moment();
      dayOfWeek = await momentDate.format("dddd").toLowerCase();
      filteredDate = await momentDate.format("YYYY-MM-DD");

      updatedMadots = await this.loadMapdotsNoDay(searchBy);
    }

    const updatedCities = await this.loadCities(updatedMadots);
    const bailoDotTitle =
      mapdot.title !== "" ? mapdot.title : mapdot.publicName;

    let lat;
    let lng;

    if (mapdot.coordinatesLatLong.Latitude) {
      lat = mapdot.coordinatesLatLong.Latitude;
      lng = mapdot.coordinatesLatLong.Longitude;
    } else {
      lat = mapdot.coordinatesLatLong.lat;
      lng = mapdot.coordinatesLatLong.lng;
    }

    try {
      await postService.sendBailoDot(bailoDotTitle).catch((error) => {
        console.log(error);
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }

    this.setState({
      filteredDate,
      searchBy,
      dayOfWeek,
      mapdotsTodaySearchBy: updatedMadots,
      citiesTodaySearchBy: updatedCities,
      centerLatitude: lat,
      centerLongitude: lng,
      zoomMap: 15,
      isModalName: false,
      visibilityFormName: false,
      loading: false,
    });
  }

  getRadioFromSpotify = async (searchBy) => {
    await postService
      .getPodcastEpisodes(searchBy)
      .then((res) => {
        this.setState({
          radioItems: res.data.body.items,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const {
      mapdotsTodaySearchBy,
      loading,
      searchBy,
      citiesTodaySearchBy,
      filterString,
      filterStringName,
      visibilityForm,
      visibilityFormName,
      // dayOfWeek,
      centerLatitude,
      centerLongitude,
      zoomMap,
      currentMapdots,
      filteredDate,
      radioItems,
      // mapdotsPerPage,
      // totalMapdots,
    } = this.state;

    const { dictionary } = this.props.lang;

    const pin = new Icon({
      iconUrl:
        "https://res.cloudinary.com/dklijqv8g/image/upload/v1624803083/bailo_marker2_bilow4.png",
      iconSize: [35, 35],
      iconAnchor: [15, 30],
    });

    return (
      <Aux>
        {/* <Breadcrumb /> */}
        {loading && <Loading />}
        {!loading && (
          <div>
            <div className="d-flex flex-row align-items-center justify-content-around flex-wrap">
              <div>
                <form onSubmit={this.handleSubmit}>
                  <div className="d-flex flex-row flex-wrap align-items-center m-10">
                    <div>
                      <SplitButton
                        type="text"
                        className="mr-2 mb-2"
                        name="searchBy"
                        id="searchBy"
                        value={searchBy}
                        title={dictionary[searchBy]}
                        variant="outline-primary"
                        onSelect={this.handleChangeSearchBy}
                      >
                        {danceCategories.map((cat) => (
                          <Dropdown.Item
                            key={cat.id}
                            eventKey={cat.type}
                            value={cat.type}
                          >
                            {dictionary[cat.type]}
                          </Dropdown.Item>
                        ))}
                      </SplitButton>
                    </div>
                    {(searchBy === "venue" ||
                      searchBy === "practica" ||
                      searchBy === "class") && (
                        <div style={{ top: "-1.1px", position: "relative" }}>
                          <input
                            variant="outline-primary"
                            type="date"
                            name="dayOfWeek"
                            id="dayOfWeek"
                            value={filteredDate}
                            onChange={this.handleChangeDate}
                          ></input>
                        </div>
                      )}
                  </div>
                </form>
              </div>

              <div className="text-center">
                <Button
                  variant="primary"
                  className="feather icon-filter"
                  onClick={() => this.setState({ isLarge: true })}
                  data-toggle="tooltip"
                  data-placement="top"
                  title={dictionary.searchFor}
                ></Button>
                <Button
                  variant="primary"
                  className="feather icon-search"
                  onClick={() => this.setState({ isModalName: true })}
                  data-toggle="tooltip"
                  data-placement="top"
                  title={dictionary.searchForMilongaName}
                ></Button>
                {/* <p>{mapdotsTodaySearchBy.length} {dictionary[searchBy]}s</p> */}
              </div>

              <Modal
                size="lg"
                show={this.state.isLarge}
                onHide={() =>
                  this.setState({ isLarge: false, visibilityForm: false })
                }
              >
                <Modal.Body
                  style={{
                    maxHeight: "calc(100vh - 210px)",
                    overflowY: "auto",
                  }}
                >
                  <form autoComplete="off">
                    <input
                      className="form-control"
                      type="search"
                      id="clickedCityId"
                      name="clickedCityId"
                      placeholder={dictionary.searchFor}
                      onChange={this.handleChangeCities}
                      aria-invalid="false"
                      autoComplete="off"
                      aria-autocomplete="list"
                    />
                    {filterString !== "" ? (
                      visibilityForm && (
                        <ul className="ul-text">
                          {citiesTodaySearchBy.filter((city) =>
                            city.cityName
                              .toLowerCase()
                              .match(filterString.toLowerCase())
                          ).length !== 0 ? (
                            citiesTodaySearchBy
                              .filter((city) =>
                                city.cityName
                                  .toLowerCase()
                                  .match(filterString.toLowerCase())
                              )
                              .map((city) => (
                                <li
                                  className="li-text"
                                  key={city._id}
                                  value={city._id}
                                  onClick={() => this.handleClickCity(city)}
                                >
                                  <a href="#" key={city._id}>
                                    {city.cityName}
                                  </a>
                                </li>
                              ))
                          ) : (
                            <div style={{ fontFamily: `Montserrat` }}>
                              <Text tid="noResultsfound" />
                            </div>
                          )}
                        </ul>
                      )
                    ) : (
                      <div></div>
                    )}
                  </form>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => this.setState({ isLarge: false })}
                  >
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>

              <Modal
                size="lg"
                show={this.state.isModalName}
                onHide={() =>
                  this.setState({
                    isModalName: false,
                    visibilityFormName: false,
                  })
                }
              >
                <Modal.Body
                  style={{
                    maxHeight: "calc(100vh - 210px)",
                    overflowY: "auto",
                  }}
                >
                  {/* <span className="d-block m-t-5">{totalMapdots}</span> */}

                  <form autoComplete="off">
                    <input
                      className="form-control"
                      type="search"
                      id="clickedMilongaId"
                      name="clickedMilongaId"
                      placeholder={dictionary.searchForMilongaName}
                      onChange={this.handleChangeFilter}
                      aria-invalid="false"
                      autoComplete="off"
                      aria-autocomplete="list"
                    />
                    {filterStringName !== "" ? (
                      visibilityFormName && (
                        <div>
                          <Table responsive hover>
                            <tbody>
                              {currentMapdots.filtered.length !== 0 ? (
                                // .filter((mapdot) =>
                                //   mapdot.title
                                //     .toLowerCase()
                                //     .match(filterStringName.toLowerCase())
                                // ).length !== 0 ? (
                                currentMapdots.filtered
                                  .slice(0)
                                  .reverse()
                                  // .filter((mapdot) =>
                                  //   mapdot.title
                                  //     .toLowerCase()
                                  //     .match(filterStringName.toLowerCase())
                                  // )
                                  .map((mapdot) => (
                                    <tr
                                      className="li-text"
                                      key={mapdot._id}
                                      value={mapdot._id}
                                      onClick={() =>
                                        this.handleClickMapdot(mapdot)
                                      }
                                    >
                                      <td>
                                        <a href="#" key={mapdot._id}>
                                          {mapdot.title !== ""
                                            ? mapdot.title
                                            : mapdot.publicName}
                                        </a>
                                      </td>
                                      <td>
                                        {mapdot.city}
                                        <br />
                                        {dictionary[mapdot.categoryIdName]}
                                        <br />
                                        {mapdot.categoryIdName !== "school" &&
                                          mapdot.categoryIdName !== "teacher" &&
                                          mapdot.categoryIdName !== "radio" &&
                                          mapdot.categoryIdName !== "musician" &&
                                          dictionary[mapdot.day]}
                                      </td>
                                    </tr>
                                  ))
                              ) : (
                                <tr>
                                  <td style={{ fontFamily: `Montserrat` }}>
                                    <Text tid="noResultsfound" />
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </Table>
                          {/* <Pagination
                          mapdotsPerPage={mapdotsPerPage}
                          totalMapdots={totalMapdots}
                          paginate={this.paginate}
                        /> */}
                        </div>
                      )
                    ) : (
                      <div></div>
                    )}
                  </form>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => this.setState({ isModalName: false })}
                  >
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>

            <ContentMap>
              <div>
                <Map
                  className="markercluster-map"
                  center={[centerLatitude, centerLongitude]}
                  zoom={zoomMap}
                  maxZoom={18}
                  scrollWheelZoom={false}
                  tap={false}
                >
                  <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  />
                  <MarkerClusterGroup>
                    {mapdotsTodaySearchBy.map((mapdot) => (
                      <div key={mapdot._id}>
                        <Marker
                          position={
                            mapdot.coordinatesLatLong.Latitude
                              ? [
                                mapdot.coordinatesLatLong.Latitude,
                                mapdot.coordinatesLatLong.Longitude,
                              ]
                              : [
                                mapdot.coordinatesLatLong.lat,
                                mapdot.coordinatesLatLong.lng,
                              ]
                          }
                          icon={pin}
                        >
                          <Popup>
                            <Link
                              to={
                                // eslint-disable-next-line no-nested-ternary
                                this.props.auth.isLoggedIn
                                  ? mapdot.title !== ""
                                    ? {
                                      pathname: `/dashboard/bailo-dots/${mapdot.title.toLowerCase()}`,
                                      state: `${mapdot._id}`,
                                    }
                                    : {
                                      pathname: `/dashboard/bailo-dots/${mapdot.publicName.toLowerCase()}`,
                                      state: `${mapdot._id}`,
                                    }
                                  : {
                                    pathname: `/login`,
                                    // state: `${mapdot._id}`,
                                  }
                              }
                            >
                              {mapdot.title !== ""
                                ? mapdot.title
                                : mapdot.publicName}
                            </Link>
                            <br />
                            <div style={{ fontFamily: "Montserrat" }}>
                              {mapdot.categoryIdName !== "teacher" &&
                                mapdot.categoryIdName !== "radio" &&
                                mapdot.categoryIdName !== "musician"
                                ? mapdot.address
                                : dictionary.addressAprox}
                              <br />
                              {mapdot.city}
                              <br />
                              {mapdot.categoryIdName !== "teacher" &&
                                mapdot.categoryIdName !== "radio" &&
                                mapdot.categoryIdName !== "musician" &&
                                dictionary[mapdot.day]}
                              {mapdot.categoryIdName === "radio" && (
                                <a
                                  href={mapdot.website}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {dictionary.radioWebsite}
                                </a>
                              )}
                            </div>
                            <br />
                            {mapdot.categoryIdName !== "teacher" &&
                              mapdot.categoryIdName !== "radio" &&
                              mapdot.categoryIdName !== "musician" && (
                                <a
                                  href={
                                    mapdot.coordinatesLatLong.Latitude
                                      ? `https://maps.google.com?q=${mapdot.coordinatesLatLong.Latitude},${mapdot.coordinatesLatLong.Longitude}`
                                      : `https://maps.google.com?q=${mapdot.coordinatesLatLong.lat},${mapdot.coordinatesLatLong.lng}`
                                  }
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <Text tid="goGoogle" />
                                </a>
                              )}
                          </Popup>
                        </Marker>
                      </div>
                    ))}
                  </MarkerClusterGroup>
                </Map>
              </div>
            </ContentMap>
            {/* {Object.keys(radioItems).length !== 0 && ( */}
            <BannerMap radioItems={radioItems} />
            {/* )} */}
          </div>
        )}
      </Aux>
    );
  }
}

MainMap.propTypes = {
  auth: PropTypes.object.isRequired,
  lang: PropTypes.object.isRequired,
  layout: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  lang: state.lang,
  layout: state.layout,
});

export default connect(mapStateToProps)(MainMap);
