/* eslint-disable no-console */
import React from "react";
import { Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";

import Aux from "../hoc/_Aux/Aux";
import logo from "../styles/images/logo.png";
import capIcon from "../styles/images/capIcon.png";
import Text from "./Text";
import LanguageSelector from "./LanguageSelector";

const BlogNav = () => (
  <Aux>
    <Navbar
      collapseOnSelect
      expand="lg"
      // fixed="top"
      className="navbar navbar-expand-lg navbar-light navbar-default navbar-fixed-top past-main"
      role="navigation"
    >
      <div className="container">
        <Navbar.Brand>
          <Link
            to={{
              pathname: `/`,
            }}
          >
            <img
              src={logo}
              alt="Bailo.app"
              className="d-inline-block align-top"
            />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          className="responsive-navbar-nav-custom"
        />
        <Navbar.Collapse
          id="responsive-navbar-nav"
          className="responsive-navbar-nav-custom"
        >
          <Nav className="ml-auto">
            <div className="navbar-nav mr-auto">
              <Nav.Link as={Link} to="/login" className="nav-link page-scroll">
                <Text tid="loginCap" />
              </Nav.Link>
              <Nav.Link as={Link} to="/signup" className="nav-link page-scroll">
                <Text tid="signupCap" />
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/gift"
                className="nav-link page-scroll"
                // style={{ fontSize: "25px" }}
              >
                <img
                  src={capIcon}
                  alt="capIcon"
                  // className="d-inline-block align-top"
                  style={{ height: "25px" }}
                />
              </Nav.Link>
              <Nav.Link as={Link} to="/blog" className="nav-link page-scroll">
                Blog
              </Nav.Link>
              <Nav.Item className="nav-link page-scroll">
                <LanguageSelector />
              </Nav.Item>
            </div>
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  </Aux>
);

export default BlogNav;
