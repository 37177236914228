/* eslint-disable no-console */
import React, { Component } from "react";

import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import Aux from "../../hoc/_Aux/Aux";
// import Breadcrumb from "../../components/Breadcrumb";
import { Styles } from "./LandingStyles";

import Loading from "../../components/Loading";
import Text from "../../components/Text";

import postService from "../../services/postService";

class LandingBlog extends Component {
  state = {
    loading: true,
    allPosts: [],
    newestPost: {},
    title: "",
    thumbnail: "",
    guid: "",
  };

  async componentDidMount() {
    try {
      await postService
        .getMediumFeed()
        .then((data) => {
          const res = data.items;
          const getNewestPost = data.items[0];
          const { title } = data.items[0];
          const { thumbnail } = data.items[0];
          const { guid } = data.items[0];

          this.setState(
            () => ({
              title,
              thumbnail,
              guid,
              newestPost: getNewestPost,
              allPosts: res,
              loading: false,
            }),
            () => {
              // console.log(this.state);
            }
          );
          // console.log(data, res);
        })
        .catch((e) => {
          // this.setState({ error: e.toJSON() })
          console.log(e);
        });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      this.setState({
        loading: false,
      });
    }
  }

  render() {
    const { loading, title, thumbnail, guid } = this.state;

    return (
      <Aux>
        <Styles>
          {loading && <Loading />}
          {!loading && (
            <div className="p-w">
              <h2>
                <Text tid="bailoBlog" />
              </h2>
              <p>
                <Text tid="bailoBlogText" />
                <br className="hidden-xs" />
                <Link to={`blog/${guid.slice(21, guid.length)}`}>{title}</Link>
              </p>

              <Link to={`blog/${title}`}>
                <div
                  style={{
                    backgroundImage: `url(${thumbnail})`,
                    position: "relative",
                    minHeight: "13.3125rem",
                    borderTopLeftRadius: "0.625rem",
                    borderTopRightRadius: "0.625rem",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                ></div>
              </Link>

              <p>
                <Text tid="bailoBlogHomepageTitle" />
                <br className="hidden-xs" />
                <Link to={`blog`}>
                  <Text tid="bailoBlog" />
                </Link>
              </p>
            </div>
          )}
        </Styles>
      </Aux>
    );
  }
}

LandingBlog.propTypes = {
  auth: PropTypes.object.isRequired,
  lang: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  lang: state.lang,
});

export default connect(mapStateToProps)(LandingBlog);
