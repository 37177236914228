/* eslint-disable class-methods-use-this */
import React from "react";
import { Row, Col } from "react-bootstrap";

import Aux from "../../hoc/_Aux/Aux";
import "../../styles/scss/style.scss";

class PrivacyPolicyEsp extends React.Component {
  render() {
    return (
      <Aux>
        <Row>
          <Col>
            <div className="card-body">
              <h1> Política de privacidad </h1>
              <p> Última actualización: 18 de junio de 2021 </p>
              <p>
                {" "}
                Esta Política de privacidad describe Nuestras políticas y
                procedimientos sobre la recopilación, uso y divulgación de su
                información cuando usa el Servicio y le informa sobre sus
                derechos de privacidad y cómo la ley lo protege.{" "}
              </p>
              <p>
                {" "}
                Usamos sus datos personales para proporcionar y mejorar el
                Servicio. Al utilizar el Servicio, acepta la recopilación y el
                uso de información de acuerdo con esta Política de privacidad.{" "}
              </p>
              <h1> Interpretación y definiciones </h1>
              <h2> Interpretación </h2>
              <p>
                {" "}
                Las palabras cuya letra inicial está en mayúscula tienen
                significados definidos bajo las siguientes condiciones. Las
                siguientes definiciones tendrán el mismo significado
                independientemente de si aparecen en singular o en plural.{" "}
              </p>
              <h2> Definiciones </h2>
              <p> A los efectos de esta Política de privacidad: </p>
              <ul>
                <li>
                  <p>
                    {" "}
                    <strong> Cuenta </strong> significa una cuenta única creada
                    para que usted acceda a nuestro Servicio o partes de nuestro
                    Servicio.{" "}
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong> Compañía </strong> (referida como & quot; la
                    Compañía &quot;, &quot; Nosotros &quot;, &quot; Nos &quot; o
                    &quot; Nuestro &quot; en este Acuerdo) se refiere a
                    Bailo.app.{" "}
                  </p>
                  <p>
                    {" "}
                    A los efectos del RGPD, la empresa es el controlador de
                    datos.{" "}
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    Las <strong> cookies </strong> son pequeños archivos que un
                    sitio web coloca en su computadora/ordenador, dispositivo
                    móvil o cualquier otro dispositivo, que contienen los
                    detalles de su historial de navegación en ese sitio web
                    entre sus muchos usos.{" "}
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong> País </strong> se refiere a: España{" "}
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong> Responsable del tratamiento </strong>, a los
                    efectos del RGPD (Reglamento general de protección de
                    datos), se refiere a la Empresa como la persona jurídica
                    que, sola o conjuntamente con otras, determina los fines y
                    medios del tratamiento de datos personales. Datos.{" "}
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong> Dispositivo </strong> se refiere a cualquier
                    dispositivo que pueda acceder al Servicio, como una
                    computadora/ordenador, un teléfono celular o una tableta
                    digital.{" "}
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong> Datos personales </strong> es cualquier información
                    relacionada con una persona identificada o identificable.{" "}
                  </p>
                  <p>
                    {" "}
                    Para los fines de GDPR, datos personales significa cualquier
                    información relacionada con usted, como un nombre, un número
                    de identificación, datos de ubicación, un identificador en
                    línea o uno o más factores específicos de los aspectos
                    físicos, fisiológicos, genéticos, mentales, económicos,
                    identidad cultural o social.{" "}
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong> Servicio </strong> se refiere al sitio web.{" "}
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong> Proveedor de servicios </strong> significa
                    cualquier persona física o jurídica que procesa los datos en
                    nombre de la Compañía. Se refiere a empresas de terceros o
                    personas empleadas por la Compañía para facilitar el
                    Servicio, para proporcionar el Servicio en nombre de la
                    Compañía, para realizar servicios relacionados con el
                    Servicio o para ayudar a la Compañía a analizar cómo se
                    utiliza el Servicio. A los efectos del RGPD, los proveedores
                    de servicios se consideran procesadores de datos.{" "}
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong> Datos de uso </strong> se refiere a los datos
                    recopilados automáticamente, ya sea generados por el uso del
                    Servicio o por la propia infraestructura del Servicio (por
                    ejemplo, la duración de una visita a la página).{" "}
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong> Sitio web </strong> se refiere a Bailo.app,
                    accesible desde{" "}
                    <a
                      href="https://bailo.app/"
                      rel="noreferrer external nofollow noopener"
                      target="_blank"
                    >
                      {" "}
                      https://bailo.app/{" "}
                    </a>{" "}
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong> Usted </strong> se refiere a la persona que accede
                    o utiliza el Servicio, o la empresa u otra entidad legal en
                    nombre de la cual dicha persona accede o utiliza el
                    Servicio, según corresponda.{" "}
                  </p>
                  <p>
                    {" "}
                    Según el RGPD (Reglamento general de protección de datos),
                    se le puede denominar el interesado o el usuario, ya que es
                    la persona que utiliza el servicio.{" "}
                  </p>
                </li>
              </ul>
              <h1> Recopilación y uso de sus datos personales </h1>
              <h2> Tipos de datos recopilados </h2>
              <h3> Datos personales </h3>
              <p>
                {" "}
                Al utilizar nuestro servicio, es posible que le pidamos que nos
                proporcione cierta información de identificación personal que
                pueda utilizarse para contactarlo o identificarlo. La
                información de identificación personal puede incluir, entre
                otros:{" "}
              </p>
              <ul>
                <li>
                  <p> Dirección de correo electrónico </p>
                </li>
                <li>
                  <p> Datos de uso </p>
                </li>
              </ul>
              <h3> Datos de uso </h3>
              <p>
                {" "}
                Los datos de uso se recopilan automáticamente cuando se utiliza
                el servicio.{" "}
              </p>
              <p>
                {" "}
                Los datos de uso pueden incluir información como la dirección de
                Protocolo de Internet de su dispositivo (por ejemplo, dirección
                IP), el tipo de navegador, la versión del navegador, las páginas
                de nuestro Servicio que visita, la hora y fecha de su visita, el
                tiempo que pasó en esas páginas, identificadores únicos de
                dispositivos y otros datos de diagnóstico.{" "}
              </p>
              <p>
                {" "}
                Cuando accede al Servicio a través de un dispositivo móvil,
                podemos recopilar cierta información automáticamente, que
                incluye, entre otros, el tipo de dispositivo móvil que utiliza,
                la identificación única de su dispositivo móvil, la dirección IP
                de su dispositivo móvil , su sistema operativo móvil, el tipo de
                navegador de Internet móvil que utiliza, identificadores únicos
                de dispositivos y otros datos de diagnóstico.{" "}
              </p>
              <p>
                {" "}
                También podemos recopilar información que su navegador envía
                cada vez que visita nuestro Servicio o cuando accede al Servicio
                a través de un dispositivo móvil.{" "}
              </p>
              <h3> Tecnologías de seguimiento y cookies </h3>
              <p>
                {" "}
                Utilizamos cookies y tecnologías de seguimiento similares para
                rastrear la actividad en Nuestro Servicio y almacenar cierta
                información. Las tecnologías de seguimiento utilizadas son
                balizas, etiquetas y scripts para recopilar y rastrear
                información y para mejorar y analizar Nuestro Servicio. Las
                tecnologías que utilizamos pueden incluir:{" "}
              </p>
              <ul>
                <li>
                  {" "}
                  <strong> Cookies o cookies del navegador. </strong> Una cookie
                  es un pequeño archivo que se coloca en su dispositivo. Puede
                  indicarle a su navegador que rechace todas las cookies o que
                  indique cuándo se está enviando una cookie. Sin embargo, si no
                  acepta las cookies, es posible que no pueda utilizar algunas
                  partes de nuestro Servicio. A menos que haya ajustado la
                  configuración de su navegador para que rechace las cookies,
                  nuestro servicio puede utilizar cookies.{" "}
                </li>
                <li>
                  {" "}
                  <strong> Cookies Flash. </strong> Ciertas funciones de nuestro
                  Servicio pueden utilizar objetos almacenados localmente (o
                  Cookies Flash) para recopilar y almacenar información sobre
                  sus preferencias o su actividad en nuestro Servicio. Las
                  cookies de Flash no se administran con la misma configuración
                  del navegador que las utilizadas para las cookies del
                  navegador. Para obtener más información sobre cómo puede
                  eliminar las cookies de Flash, lea &quot; ¿Dónde puedo cambiar
                  la configuración para deshabilitar o eliminar objetos
                  compartidos locales? &quot; disponible en{" "}
                  <a
                    href="https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_"
                    rel="noreferrer external nofollow noopener"
                    target="_blank"
                  >
                    https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_
                  </a>
                </li>
                <li>
                  {" "}
                  <strong> Web Beacons. </strong> Ciertas secciones de nuestro
                  Servicio y nuestros correos electrónicos pueden contener
                  pequeños archivos electrónicos conocidos como web beacons
                  (también conocidos como gifs transparentes, etiquetas de
                  píxeles y gifs de un solo píxel) que le permiten a la Empresa,
                  por ejemplo, para contar los usuarios que visitaron esas
                  páginas o abrieron un correo electrónico y para otras
                  estadísticas del sitio web relacionadas (por ejemplo,
                  registrar la popularidad de una determinada sección y
                  verificar la integridad del sistema y del servidor).{" "}
                </li>
              </ul>
              <p>
                {" "}
                Las cookies pueden ser &quot; persistentes &quot; o &quot;
                Sesión &quot; cookies. Las cookies persistentes permanecen en su
                computadora/ordenador personal o dispositivo móvil cuando se
                desconecta, mientras que las cookies de sesión se eliminan tan
                pronto como cierra su navegador web. Puede obtener más
                información sobre las cookies aquí:{" "}
                <a
                  href="https://www.termsfeed.com/blog/cookies/"
                  rel="noreferrer"
                  target="_blank"
                >
                  {" "}
                  Todo sobre las cookies por TermsFeed{" "}
                </a>
                .{" "}
              </p>
              <p>
                {" "}
                Utilizamos cookies de sesión y persistentes para los fines que
                se establecen a continuación:{" "}
              </p>
              <ul>
                <li>
                  <p>
                    {" "}
                    <strong> Cookies necesarias / esenciales </strong>{" "}
                  </p>
                  <p> Tipo: Cookies de sesión </p>
                  <p> Administrado por: Nosotros </p>
                  <p>
                    {" "}
                    Propósito: estas cookies son esenciales para brindarle los
                    servicios disponibles a través del sitio web y para
                    permitirle utilizar algunas de sus funciones. Ayudan a
                    autenticar a los usuarios y a prevenir el uso fraudulento de
                    las cuentas de usuario. Sin estas cookies, los servicios que
                    ha solicitado no se pueden proporcionar, y solo usamos estas
                    cookies para brindarle esos servicios.{" "}
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong>
                      {" "}
                      Política de cookies / Cookies de aceptación de avisos{" "}
                    </strong>{" "}
                  </p>
                  <p> Tipo: cookies persistentes </p>
                  <p> Administrado por: Nosotros </p>
                  <p>
                    {" "}
                    Finalidad: estas cookies identifican si los usuarios han
                    aceptado el uso de cookies en el sitio web.{" "}
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong> Cookies de funcionalidad </strong>{" "}
                  </p>
                  <p> Tipo: cookies persistentes </p>
                  <p> Administrado por: Nosotros </p>
                  <p>
                    {" "}
                    Objetivo: estas cookies nos permiten recordar las elecciones
                    que realiza cuando utiliza el sitio web, como recordar sus
                    datos de inicio de sesión o su preferencia de idioma. El
                    propósito de estas cookies es brindarle una experiencia más
                    personal y evitar que tenga que volver a ingresar sus
                    preferencias cada vez que utiliza el sitio web.{" "}
                  </p>
                </li>
              </ul>
              <p>
                {" "}
                Para obtener más información sobre las cookies que utilizamos y
                sus opciones con respecto a las cookies, visite nuestra Política
                de cookies o la sección de Cookies de nuestra Política de
                privacidad.{" "}
              </p>
              <h2> Uso de sus datos personales </h2>
              <p>
                {" "}
                La Compañía puede usar Datos Personales para los siguientes
                propósitos:{" "}
              </p>
              <ul>
                <li>
                  <p>
                    {" "}
                    <strong>
                      {" "}
                      Para proporcionar y mantener nuestro Servicio{" "}
                    </strong>
                    , incluso para supervisar el uso de nuestro Servicio.{" "}
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong> Para administrar su cuenta: </strong> para
                    administrar su registro como usuario del servicio. Los Datos
                    Personales que proporcione pueden darle acceso a diferentes
                    funcionalidades del Servicio que están disponibles para
                    Usted como usuario registrado.{" "}
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong> Para la ejecución de un contrato: </strong> el
                    desarrollo, cumplimiento y ejecución del contrato de compra
                    de los productos, artículos o servicios que ha comprado o de
                    cualquier otro contrato con nosotros a través del Servicio.{" "}
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong> Para contactarlo: </strong> Para contactarlo por
                    correo electrónico, llamadas telefónicas, SMS u otras formas
                    equivalentes de comunicación electrónica, como
                    notificaciones automáticas de aplicaciones móviles con
                    respecto a actualizaciones o comunicaciones informativas
                    relacionadas con el funcionalidades, productos o servicios
                    contratados, incluidas las actualizaciones de seguridad,
                    cuando sea necesario o razonable para su implementación.{" "}
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong> Para proporcionarle </strong> noticias, ofertas
                    especiales e información general sobre otros bienes,
                    servicios y eventos que ofrecemos y que son similares a los
                    que ya ha comprado o sobre los que ha consultado, a menos
                    que haya optado por no hacerlo. recibir dicha información.{" "}
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong> Para gestionar sus solicitudes: </strong> para
                    atender y gestionar sus solicitudes para nosotros.{" "}
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong> Para transferencias comerciales: </strong> podemos
                    utilizar su información para evaluar o realizar una fusión,
                    desinversión, reestructuración, reorganización, disolución u
                    otra venta o transferencia de algunos o todos nuestros
                    activos, ya sea como empresa en marcha o como parte de una
                    quiebra, liquidación o procedimiento similar, en el que los
                    Datos personales que tenemos sobre los usuarios de nuestro
                    Servicio se encuentran entre los activos transferidos.{" "}
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong> Para otros fines </strong>: podemos utilizar su
                    información para otros fines, como análisis de datos,
                    identificación de tendencias de uso, determinación de la
                    efectividad de nuestras campañas promocionales y para
                    evaluar y mejorar nuestro Servicio, productos y servicios. ,
                    marketing y tu experiencia.{" "}
                  </p>
                </li>
              </ul>
              <p>
                {" "}
                Podemos compartir su información personal en las siguientes
                situaciones:{" "}
              </p>
              <ul>
                <li>
                  {" "}
                  <strong> Con proveedores de servicios: </strong> podemos
                  compartir su información personal con proveedores de servicios
                  para monitorear y analizar el uso de nuestro servicio, para
                  comunicarnos con usted.{" "}
                </li>
                <li>
                  {" "}
                  <strong> Para transferencias comerciales: </strong> podemos
                  compartir o transferir Su información personal en relación
                  con, o durante las negociaciones de, cualquier fusión, venta
                  de activos de la Compañía, financiamiento o adquisición de la
                  totalidad o una parte de Nuestra negocio a otra empresa.{" "}
                </li>
                <li>
                  {" "}
                  <strong> Con afiliados: </strong> podemos compartir su
                  información con nuestros afiliados, en cuyo caso les
                  solicitaremos que cumplan con esta Política de privacidad. Los
                  afiliados incluyen nuestra empresa matriz y cualquier otra
                  subsidiaria, socios de empresas conjuntas u otras empresas que
                  controlemos o que estén bajo control común con nosotros.{" "}
                </li>
                <li>
                  {" "}
                  <strong> Con socios comerciales: </strong> podemos compartir
                  su información con nuestros socios comerciales para ofrecerle
                  ciertos productos, servicios o promociones.{" "}
                </li>
                <li>
                  {" "}
                  <strong> Con otros usuarios: </strong> cuando comparte
                  información personal o interactúa en las áreas públicas con
                  otros usuarios, dicha información puede ser vista por todos
                  los usuarios y puede ser distribuida públicamente afuera.{" "}
                </li>
                <li>
                  {" "}
                  <strong> Con su consentimiento </strong>: podemos divulgar su
                  información personal para cualquier otro propósito con su
                  consentimiento.{" "}
                </li>
              </ul>
              <h2> Conservación de sus datos personales </h2>
              <p>
                {" "}
                La Compañía retendrá sus datos personales solo durante el tiempo
                que sea necesario para los fines establecidos en esta Política
                de privacidad. Retendremos y usaremos sus datos personales en la
                medida necesaria para cumplir con nuestras obligaciones legales
                (por ejemplo, si estamos obligados a retener sus datos para
                cumplir con las leyes aplicables), resolver disputas y hacer
                cumplir nuestros acuerdos y políticas legales.{" "}
              </p>
              <p>
                {" "}
                La Compañía también retendrá los datos de uso con fines de
                análisis interno. Los datos de uso generalmente se conservan
                durante un período de tiempo más corto, excepto cuando estos
                datos se utilizan para fortalecer la seguridad o mejorar la
                funcionalidad de nuestro servicio, o estamos legalmente
                obligados a conservar estos datos durante períodos de tiempo más
                largos.{" "}
              </p>
              <h2> Transferencia de sus datos personales </h2>
              <p>
                {" "}
                Su información, incluidos los datos personales, se procesa en
                las oficinas operativas de la Compañía y en cualquier otro lugar
                donde se encuentren las partes involucradas en el procesamiento.
                Significa que esta información puede transferirse y mantenerse
                en computadoras ubicadas fuera de su estado, provincia, país u
                otra jurisdicción gubernamental donde las leyes de protección de
                datos pueden diferir de las de su jurisdicción.{" "}
              </p>
              <p>
                {" "}
                Su consentimiento a esta Política de privacidad seguido de Su
                envío de dicha información representa su aceptación de esa
                transferencia.{" "}
              </p>
              <p>
                {" "}
                La Compañía tomará todas las medidas razonablemente necesarias
                para garantizar que Sus datos sean tratados de forma segura y de
                acuerdo con esta Política de Privacidad y no se realizará
                ninguna transferencia de sus datos personales a una organización
                o país a menos que existan controles adecuados establecidos,
                incluyendo la seguridad de sus datos y otra información
                personal.{" "}
              </p>
              <h2> Divulgación de sus datos personales </h2>
              <h3> Transacciones comerciales </h3>
              <p>
                {" "}
                Si la Compañía está involucrada en una fusión, adquisición o
                venta de activos, sus datos personales pueden ser transferidos.
                Le enviaremos un aviso antes de que sus datos personales se
                transfieran y estén sujetos a una política de privacidad
                diferente.{" "}
              </p>
              <h3> Aplicación de la ley </h3>
              <p>
                {" "}
                En determinadas circunstancias, es posible que se le solicite a
                la Compañía que revele sus datos personales si así lo exige la
                ley o en respuesta a solicitudes válidas de las autoridades
                públicas (por ejemplo, un tribunal o una agencia gubernamental).{" "}
              </p>
              <h3> Otros requisitos legales </h3>
              <p>
                {" "}
                La Compañía puede divulgar sus datos personales con la creencia
                de buena fe de que dicha acción es necesaria para:{" "}
              </p>
              <ul>
                <li> Cumplir con una obligación legal </li>
                <li>
                  {" "}
                  Proteger y defender los derechos o la propiedad de la Compañía{" "}
                </li>
                <li>
                  {" "}
                  Prevenir o investigar posibles irregularidades en relación con
                  el Servicio{" "}
                </li>
                <li>
                  {" "}
                  Proteger la seguridad personal de los usuarios del Servicio o
                  del público{" "}
                </li>
                <li> Protéjase contra la responsabilidad legal </li>
              </ul>
              <h2> Seguridad de sus datos personales </h2>
              <p>
                {" "}
                La seguridad de sus datos personales es importante para
                nosotros, pero recuerde que ningún método de transmisión a
                través de Internet o método de almacenamiento electrónico es
                100% seguro. Si bien nos esforzamos por utilizar medios
                comercialmente aceptables para proteger sus datos personales, no
                podemos garantizar su seguridad absoluta.{" "}
              </p>
              <h1> Privacidad GDPR </h1>
              <h2> Base legal para procesar datos personales bajo GDPR </h2>
              <p>
                {" "}
                Podemos procesar datos personales en las siguientes condiciones:{" "}
              </p>
              <ul>
                <li>
                  {" "}
                  <strong> Consentimiento: </strong> ha otorgado su
                  consentimiento para procesar datos personales para uno o más
                  propósitos específicos.{" "}
                </li>
                <li>
                  {" "}
                  <strong> Ejecución de un contrato: </strong> la provisión de
                  datos personales es necesaria para la ejecución de un acuerdo
                  con usted y/o para cualquier obligación precontractual del
                  mismo.{" "}
                </li>
                <li>
                  {" "}
                  <strong> Obligaciones legales: </strong> el procesamiento de
                  datos personales es necesario para el cumplimiento de una
                  obligación legal a la que está sujeta la empresa.{" "}
                </li>
                <li>
                  {" "}
                  <strong> Intereses vitales: </strong> el procesamiento de
                  datos personales es necesario para proteger sus intereses
                  vitales o los de otra persona física.{" "}
                </li>
                <li>
                  {" "}
                  <strong> Interés público: </strong> el procesamiento de datos
                  personales está relacionado con una tarea que se lleva a cabo
                  en el interés público o en el ejercicio de la autoridad
                  oficial conferida a la Compañía.{" "}
                </li>
                <li>
                  {" "}
                  <strong> Intereses legítimos: </strong> el procesamiento de
                  datos personales es necesario para los fines de los intereses
                  legítimos que persigue la Compañía.{" "}
                </li>
              </ul>
              <p>
                {" "}
                En cualquier caso, la Compañía con gusto ayudará a aclarar la
                base legal específica que se aplica al procesamiento y, en
                particular, si el suministro de datos personales es un requisito
                legal o contractual, o un requisito necesario para celebrar un
                contrato.{" "}
              </p>
              <h2> Sus derechos según el RGPD </h2>
              <p>
                {" "}
                La Compañía se compromete a respetar la confidencialidad de sus
                datos personales y a garantizarle que puede ejercer sus
                derechos.{" "}
              </p>
              <p>
                {" "}
                En virtud de esta Política de privacidad, y por ley, si se
                encuentra dentro de la UE, tiene derecho a:{" "}
              </p>
              <ul>
                <li>
                  {" "}
                  <strong> Solicite acceso a sus datos personales. </strong> El
                  derecho a acceder, actualizar o eliminar la información que
                  tenemos sobre usted. Siempre que sea posible, puede acceder,
                  actualizar o solicitar la eliminación de sus datos personales
                  directamente en la sección de configuración de su cuenta. Si
                  no puede realizar estas acciones usted mismo, comuníquese con
                  nosotros para ayudarlo. Esto también le permite recibir una
                  copia de los datos personales que tenemos sobre usted.{" "}
                </li>
                <li>
                  {" "}
                  <strong>
                    {" "}
                    Solicite la corrección de los datos personales que tenemos
                    sobre usted.{" "}
                  </strong>{" "}
                  Tiene derecho a que se corrija cualquier información
                  incompleta o inexacta que tengamos sobre usted.{" "}
                </li>
                <li>
                  {" "}
                  <strong>
                    {" "}
                    Objetar el procesamiento de sus datos personales.{" "}
                  </strong>{" "}
                  Este derecho existe cuando confiamos en un interés legítimo
                  como base legal para nuestro procesamiento y hay algo sobre su
                  situación particular que hace que desee oponerse a nuestro
                  procesamiento de sus datos personales por este motivo. También
                  tiene derecho a oponerse cuando procesamos sus datos
                  personales con fines de marketing directo.{" "}
                </li>
                <li>
                  {" "}
                  <strong>
                    {" "}
                    Solicitar la eliminación de sus datos personales.{" "}
                  </strong>{" "}
                  Tiene derecho a solicitarnos que eliminemos o eliminemos los
                  datos personales cuando no haya una buena razón para que
                  continuemos procesándolos.{" "}
                </li>
                <li>
                  {" "}
                  <strong>
                    {" "}
                    Solicite la transferencia de sus datos personales.{" "}
                  </strong>{" "}
                  Le proporcionaremos a usted, oa un tercero que haya elegido,
                  sus datos personales en un formato estructurado, de uso común
                  y legible por máquina. Tenga en cuenta que este derecho solo
                  se aplica a la información automatizada que inicialmente nos
                  dio su consentimiento para que la usemos o cuando usamos la
                  información para ejecutar un contrato con usted.{" "}
                </li>
                <li>
                  {" "}
                  <strong> Retirar su consentimiento. </strong> Tiene derecho a
                  retirar su consentimiento sobre el uso de sus datos
                  personales. Si retira su consentimiento, es posible que no
                  podamos brindarle acceso a ciertas funcionalidades específicas
                  del Servicio.{" "}
                </li>
              </ul>
              <h2>
                {" "}
                Ejercicio de sus derechos de protección de datos de GDPR{" "}
              </h2>
              <p>
                {" "}
                Puede ejercitar sus derechos de acceso, rectificación,
                cancelación y oposición poniéndose en contacto con nosotros.
                Tenga en cuenta que es posible que le pidamos que verifique su
                identidad antes de responder a dichas solicitudes. Si realiza
                una solicitud, haremos todo lo posible para responderle lo antes
                posible.{" "}
              </p>
              <p>
                {" "}
                Tiene derecho a presentar una queja ante una autoridad de
                protección de datos sobre nuestra recopilación y uso de sus
                datos personales. Para obtener más información, si se encuentra
                en el Espacio Económico Europeo (EEE), comuníquese con su
                autoridad local de protección de datos en el EEE.{" "}
              </p>
              <h1> Privacidad de los niños </h1>
              <p>
                {" "}
                Nuestro servicio no se dirige a ninguna persona menor de 13
                años. No recopilamos a sabiendas información de identificación
                personal de ninguna persona menor de 13 años. Si usted es un
                padre o tutor y sabe que su hijo nos ha proporcionado
                información personal Datos, contáctenos. Si nos damos cuenta de
                que hemos recopilado datos personales de cualquier persona menor
                de 13 años sin la verificación del consentimiento de los padres,
                tomamos medidas para eliminar esa información de nuestros
                servidores.{" "}
              </p>
              <p>
                {" "}
                Si necesitamos depender del consentimiento como base legal para
                procesar su información y su país requiere el consentimiento de
                uno de los padres, es posible que necesitemos el consentimiento
                de sus padres antes de recopilar y utilizar esa información.{" "}
              </p>
              <h1> Enlaces a otros sitios web </h1>
              <p>
                {" "}
                Nuestro Servicio puede contener enlaces a otros sitios web que
                no son operados por nosotros. Si hace clic en el enlace de un
                tercero, se le dirigirá al sitio de ese tercero. Le recomendamos
                encarecidamente que revise la Política de privacidad de cada
                sitio que visite.{" "}
              </p>
              <p>
                {" "}
                No tenemos control ni asumimos ninguna responsabilidad por el
                contenido, las políticas de privacidad o las prácticas de sitios
                o servicios de terceros.{" "}
              </p>
              <h1> Cambios en esta Política de privacidad </h1>
              <p>
                {" "}
                Podemos actualizar nuestra Política de privacidad de vez en
                cuando. Le notificaremos de cualquier cambio publicando la nueva
                Política de privacidad en esta página.{" "}
              </p>
              <p>
                {" "}
                Le informaremos por correo electrónico y/o un aviso destacado en
                nuestro servicio, antes de que el cambio entre en vigencia y
                actualizaremos la &quot; Última actualización &quot; fecha en la
                parte superior de esta Política de privacidad.{" "}
              </p>
              <p>
                {" "}
                Le recomendamos que revise esta Política de privacidad
                periódicamente para ver si hay cambios. Los cambios a esta
                Política de privacidad entran en vigencia cuando se publican en
                esta página.{" "}
              </p>
              <h1> Contáctenos </h1>
              <p>
                {" "}
                Si tiene alguna pregunta sobre esta Política de privacidad,
                puede contactarnos:{" "}
              </p>
              <ul>
                <li> Por correo electrónico: bailo.app@gmail.com </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Aux>
    );
  }
}

export default PrivacyPolicyEsp;
