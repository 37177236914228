import React from "react";

import Aux from "../../hoc/_Aux/Aux";
import PayPal from "../../components/PayPal";
import '../../styles/scss/style.scss';

import { Styles } from "../landing/LandingStyles";
import NavLanding from "../../components/NavLanding";

const Gift = () => (
  <Aux>
    <Styles>
      <div className="card-body">
        <div className="container">
          <NavLanding />
          <div
            style={{ paddingTop: '100px' }}
          >
            <PayPal />
          </div>
        </div>
      </div>
    </Styles>
  </Aux >
);

export default Gift;