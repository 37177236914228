const listOfQA = [
  {
    id: 1,
    eng: { title: 'How do I add a milonga / práctica / class?', text: 'You might want to login and go to Menu >> My Bailo >> Add Bailo Dot and choose in the field category whether it is a milonga, práctica or a class. Bailo Dots are all tango spots on the map you can find - be it a milonga, práctica, class or in the near future a shop where you can buy tango shoes or simply tango festivals.' },
    spa: { title: '¿Cómo puedo añadir una milonga / práctica / clase?', text: 'Deberías iniciar tu sesión e ir a Menu >> Mi Bailo >> Añadir Bailo Dot y elegir en la parte de la categoría si se trata de una milonga, práctica o una clase. Bailo Dots (dot en inglés quiere decir punto) son todos los puntos que se pueden encontrar en el mapa, sea una milonga, práctica, clase o en el futuro tiendas donde se puede comprar zapatos de tango o simplemente festivales de tango.' },
  },
  {
    id: 2,
    eng: { title: 'What is a Bailo Dot?', text: 'Bailo Dots are all tango spots you can find on the map. In the category field you can then filter all Bailo Dots by milongas, prácticas or clases.' },
    spa: { title: '¿Qué son los Bailo Dots?', text: 'Los Bailo Dots son todos los puntos de baile que se pueden encontrar en el mapa. En el campo de categoría se puede filtrar los Bailo Dots por milongas, prácticas o clases.' },
  },
  {
    id: 3,
    eng: { title: 'I have found my milonga / práctica / class on the map. How can I access and update it?', text: 'Contact us via mail and we will give you the credentials, so you can keep your Bailo Dot up to date.' },
    spa: { title: 'Encontré mi milonga / práctica / clase en el mapa. ¿Cómo puedo accederla y actualizarla?', text: 'Contáctanos vía mail y te daremos los credenciales para que puedas acceder y actualizar el Bailo Dot.' },
  },
  {
    id: 4,
    eng: { title: 'How do I contact you if I have any further questions?', text: 'You can send us an email: bailo.app@gmail.com and we will get back to you as soon as possible.' },
    spa: { title: '¿Cómo puedo ponerme en contacto con ustedes si tengo preguntas?', text: 'Podrás mandarnos un mail a: bailo.app@gmail.com y te respondemos ni bien podamos.' },
  },
  {
    id: 5,
    eng: {
      title: 'Can I work with Bailo.app?', text: "Feel free to contact us via mail: bailo.app@gmail.com - We are two tango addicts who work on Bailo.app in our free time and are happy to work with other tango aficionados."
    },
    spa: { title: '¿Puedo trabajar con Bailo.app?', text: 'Mándanos un mail a: bailo.app@gmail.com - Somos dos amantes de tango y trabajamos en Bailo.app en nuestro tiempo libre. Estamos encantados de trabajar con otros amantes de tango.' },
  },
  {
    id: 6,
    eng: { title: 'What is the difference between "Follow" and "Lead" Bailo Dot?', text: 'When you hit "like" to a Bailo Dot, it will be added to your Follow list - so these are all your Bailo Dots you like. Whereas, when you add a Bailo Dot, you are the organiser of a milonga, prácticta or class - hence you "lead" one or several Bailo Dots.' },
    spa: { title: '¿Cuál es la diferencia entre el "Follow" / "Siguiendo" y "Lead" / "Gestión de"?', text: 'Cuando le das un "like" a una Bailo Dot, eso quiere decir que le sigues a una milonga, práctica o clase - entonces son los Bailo Dots que te gustan. Mientras si añades una milonga, práctica o clase, serás la o el organizador/a de un o varios Bailo Dots, entonces los gestionas.' },
  },
  {
    id: 7,
    eng: { title: 'How do I use the map?', text: 'There are two main options: Either you search for a milonga / práctica / clase by its name and it will give you the day and location of it, or you choose a day and then filter by category and cities and it will give you all Bailo Dots on that day and in that city.' },
    spa: { title: '¿Cómo puedo usar el mapa?', text: 'Hay dos opciones: O buscas por una milonga / práctica / clase por su nombre y te dará el día y la úbicación, o eliges el día y filtras por categoría y ciudad para ver cuales Bailo Dots hay en tal día y tal ciudad.' },
  },
  {
    id: 8,
    eng: { title: 'The milonga / práctica / class I found is not up to date.', text: 'Please, do let us know about it and we will check on that and let the organiser know about it.' },
    spa: { title: 'La milonga / práctica / clase que encontré no está actualizada.', text: 'Por favor, mándanos un mail así lo chequeamos y avisamos al organizador del Bailo Dot.' },
  },
  {
    id: 9,
    eng: { title: 'I would like to add a tango festival or shop to the map. How can I do that?', text: 'At the momento we are still working on adding more filters like: festivals, shops, bands, singers... As soon as we have them working, we will let you know.' },
    spa: { title: 'Me gustaría añadir un festival de tango o una tienda al mapa. ¿Cómo lo puedo hacer?', text: 'De momento estamos aún trabajando en añadir otro filtros como los festivales de tango, tiendas, bandas, cantantes... Ni bien lo tengamos en producción, te avisaremos.' },
  },
  {
    id: 10,
    eng: { title: 'I have found a bug / coding error.', text: 'Congrats! Let us know via mail, please. We love solving erros. 😃 ' },
    spa: { title: 'Encontré un fallo en la página web.', text: '¡Buenísimo! Por favor, avísanos vía mail. Nos encanta resolver los fallos en nuestro código. 😃 ' },
  },
  {
    id: 11,
    eng: { title: 'Bailo.app is for free, right?', text: 'Yes, it is 😃 .' },
    spa: { title: 'Bailo.app es gratis, ¿verdad?', text: 'Sí, es gratis 😃 .' },
  },
];

module.exports = {
  listOfQA,
};
