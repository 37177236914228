/* eslint-disable class-methods-use-this */
import axios from "axios";

class CityService {
  async getAllCities() {
    const {
      data: cities,
    } = await axios
      .get(`${process.env.REACT_APP_BACKEND_URI}/api/cities`,
        {},
        {
          headers: {
            Authorization: `${localStorage.jwtToken}`,
          },
        },
      );
    return cities;
  }

  async getCityById(
    id,
  ) {
    const {
      data: city,
    } = await axios
      .get(`${process.env.REACT_APP_BACKEND_URI}/api/cities/${id}`,
        {},
        {
          headers: {
            Authorization: `${localStorage.jwtToken}`,
          },
        },
      );
    return city;
  }

  async searchCities(
    filteredCityIds,
  ) {
    const {
      data,
    } = await axios
      .get(`${process.env.REACT_APP_BACKEND_URI}/api/cities/search`,
        {
          params: {
            filteredCityIds,
          }
        },
        {},
      );
    return data;
  }

}

const cityService = new CityService();

export default cityService;
