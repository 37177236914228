/* eslint-disable class-methods-use-this */
import React from 'react';
import { NavLink } from 'react-router-dom';

import Aux from "../../hoc/_Aux/Aux";
import '../../styles/scss/style.scss';
// import Breadcrumb from "../../components/Breadcrumb";

class ErrorPage extends React.Component {
  render() {
    return (
      <Aux>
        {/* <Breadcrumb /> */}
        <div className="auth-wrapper offline">
          <div className="text-center">
            <h1 className="mb-4">ERROR 404</h1>
            <h5 className="text-muted mb-4">This page does not exist</h5>
            <NavLink to="/" className="btn btn-primary mb-4">
              <i className="feather icon-home" />
              Back to Home
            </NavLink>
          </div>
        </div>
      </Aux>
    );
  }
}

export default ErrorPage;