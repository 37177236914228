/* eslint-disable class-methods-use-this */
import axios from "axios";

class AuthService {
  async signup(user) {
    const {
      username,
      password,
      emailUser,
      password2,
      roleIdName,
      myCity,
      myCountry,
      myPostalCode,
      // latitude,
      // longitude,
      // online,
    } = user;
    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_URI}/signup`,
      {
        username,
        password,
        emailUser,
        password2,
        roleIdName,
        myCity,
        myCountry,
        myPostalCode,
        // latitude,
        // longitude,
        // online,
      },
      {}
    );
    return data;
  }

  async login(user) {
    const {
      username,
      password,
      // latitude,
      // longitude,
    } = user;
    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_URI}/login`,
      {
        username,
        password,
        // latitude,
        // longitude,
      },
      {}
    );
    return data;
  }

  async updatepassword(password, password2, userId) {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_URI}/${userId}/updatepassword`,
      {
        password,
        password2,
      },
      {},
      {
        headers: {
          Authorization: `${localStorage.jwtToken}`,
        },
      }
    );
    return data;
  }

  async googleSignup(tokenId) {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_URI}/google-signup`,
      {
        tokenId,
      },
      {}
    );
    return data;
  }

  async googleLogin(tokenId) {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_URI}/google-login`,
      {
        tokenId,
      },
      {}
    );
    return data;
  }

  async facebookLogin(newUser) {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_URI}/facebook-login`,
      {
        newUser,
      },
      {}
    );
    return data;
  }

  async facebookSignup(newUser) {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_URI}/facebook-signup`,
      {
        newUser,
      },
      {}
    );
    return data;
  }

  async confirmMail(id) {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URI}/email/confirm/${id}`,
      {},
      {}
    );
    return data;
  }

  // async logout() {
  //   const response = await axios.get(`${process.env.REACT_APP_BACKEND_URI}/logout`, {});
  //   return response.data;
  // }

  // async whoami() {
  //   const response = await axios.get(`${process.env.REACT_APP_BACKEND_URI}/whoami`);
  //   return response.data;
  // }

  // abandon() {
  //   return axios.get(`${process.env.REACT_APP_BACKEND_URI}/abandon`);
  // }

  // remember() {
  //   return axios.get(`${process.env.REACT_APP_BACKEND_URI}/remember`);
  // }
}

const authService = new AuthService();

export default authService;
