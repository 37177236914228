import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
// import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import rootReducer from "./reducers/reducer";
// import { createLogger } from 'redux-logger';

// const initialState = {};

// const middleware = [thunk];

const store = createStore(
  // rootReducer,
  // initialState,
  // compose(
  //   applyMiddleware(...middleware),
  //   window.navigator.userAgent.includes("Chrome")
  //     ? window.__REDUX_DEVTOOLS_EXTENSION__ &&
  //     window.__REDUX_DEVTOOLS_EXTENSION__()
  //     : compose
  rootReducer,
  compose(
    applyMiddleware(thunk),
    typeof window.__REDUX_DEVTOOLS_EXTENSION__ === "undefined"
      ? (a) => a
      : window.__REDUX_DEVTOOLS_EXTENSION__ &&
          window.__REDUX_DEVTOOLS_EXTENSION__()
  )
);

export default store;
