/* eslint-disable react/prop-types */
import React from "react";
import moment from "moment";
import { Link, withRouter } from "react-router-dom";
import c from "./ShowBlog.module.css";
import ToText from "./ToText";

function ShowBlog(props) {
  return (
    <div className={`col-md-4 col-sm-6 col-xs-12 ${c.grid}`}>
      <div className={c.cardsmall}>
        <div
          className={c.cardpost__image}
          style={{ backgroundImage: `url(${props.thumbnail})` }}
        >
          <div className={c.authorimg}>
            <a
              href={props.profileurl}
              rel="noopener noreferrer"
              target="_blank"
              style={{ backgroundImage: `url(${props.avtar})` }}
            >
            </a>
          </div>
        </div>

        <div className="card-body">
          <h5 className="card-title">
            <Link
              to={`blog/${props.guid.slice(21, props.guid.length)}`}
              className={c.textfiordblue}
              style={{ fontFamily: `Arciform` }}
            >
              {props.title}
            </Link>
          </h5>

          <p className={c.cardText}>
            {`${ToText(
              props.description.substring(0, 1000)
            )}...`}
          </p>
          <br />

          <span className="text-dark">
            {props.author}
          </span>

          <br />
          <span className="text-muted">
            {moment(props.pubDate).format("DD MMMM, YYYY HH:mm")}
          </span>
        </div>
      </div>
    </div>
  );
}

export default withRouter(ShowBlog);