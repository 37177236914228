/* eslint-disable react/prop-types */
/* eslint-disable class-methods-use-this */
/* eslint-disable import/no-duplicates */
import React, { Component } from "react";
import { Carousel } from "react-bootstrap";
import PodcastAudioPlay from "./PodcastAudioPlay";
import Text from "../../../components/Text";

export default class BannerMap extends Component {
  state = {
    radioAudioPlay: "",
  };

  render() {
    const { radioItems } = this.props;

    if (Object.keys(radioItems).length !== 0) {
      this.radioAudioPlay = radioItems.slice(0)[0].id;
    }

    return (
      <>
        {Object.keys(radioItems).length !== 0 && (
          <div id="slider">
            <h1 className="banner-title">
              <Text tid="whatIsHot" />
            </h1>

            <Carousel className="carrousel-custom" interval={10000}>
              <Carousel.Item>
                <div className="d-flex flex-row">
                  <div className="d-flex flex-column align-items-center w-100">
                    <PodcastAudioPlay audioPlay={this.radioAudioPlay} />
                  </div>
                </div>
              </Carousel.Item>
              {/* <Carousel.Item>
                <h2>Anunce here</h2>
              </Carousel.Item> */}
              {/*  <Carousel.Item>
            <img
              className="d-block w-100"
              src="http://via.placeholder.com/350x350/45b69c/ffffff?text=slider4.jpeg"
            />
          </Carousel.Item> */}
            </Carousel>
          </div>
        )}
      </>
    );
  }
}
