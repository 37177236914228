import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import { PropTypes } from "prop-types";

// eslint-disable-next-line react/prop-types
const PrivateRoute = ({ component: Comp, auth, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      auth.isLoggedIn === true ? (
        <Comp {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/',
            // state: { from: props.location },
          }}
        />
      )
    }
  />
);

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(PrivateRoute);