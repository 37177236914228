/* eslint-disable class-methods-use-this */
import React from 'react';

import { Row, Col } from 'react-bootstrap';
import Aux from "../../hoc/_Aux/Aux";
import '../../styles/scss/style.scss';
// import Breadcrumb from "../../components/Breadcrumb";
import KnowledgeBase from "../knowledgeBase/KnowledgeBase";
import LanguageSelector from "../../components/LanguageSelector";

class LandingKB extends React.Component {
  render() {
    return (
      <Aux>
        {/* <Breadcrumb /> */}
        <Row>
          <Col>
            <div className="card-body">
              <LanguageSelector />
              <hr />
              <KnowledgeBase />
            </div>
          </Col>
        </Row>
      </Aux>
    );
  }
}

export default LandingKB;