/* eslint-disable prefer-template */
/* eslint-disable no-undef */
/* eslint-disable class-methods-use-this */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// import "../../styles/landing/css/styles.scss";
import ReactGA from "react-ga";

import Aux from "../../hoc/_Aux/Aux";
// import Breadcrumb from "../components/Breadcrumb";

import MainMap from "../map/MainMap";
import LandingBlog from "./LandingBlog";
import LandingYouTube from "./LandingYouTube";

import NavLanding from "../../components/NavLanding";

import Text from "../../components/Text";

import logo from "../../styles/images/logo.png";
import tashIcon from "../../styles/images/landing/tashIcon.png";
import marceIcon from "../../styles/images/landing/marceIcon.png";
import iphoneMoc from "../../styles/images/landing/iPhone11.png";
import playStoreIcon from "../../styles/images/landing/playstore.png";
import appStoreIcon from "../../styles/images/landing/appstore.png";

import { Styles } from "./LandingStyles";

ReactGA.initialize(process.env.REACT_APP_GA_ID_TRACKING);

const Landing = () => {
  useEffect(() => {
    ReactGA.pageview("landingPage" + window.location.search);
  });

  return (
    <Aux>
      {/* <Breadcrumb /> */}
      <Styles>
        <div className="wrapper">
          <div>
            <NavLanding />

            <div className="main" id="main">
              <div className="main" id="main">
                <div className="hero-section-landing landing-section-custom app-landing">
                  <div className="container">
                    <div className="hero-content-custom app-landing-content-custom">
                      <div className="row hero-content-landing">
                        <div className="col-md-9 header-text-landing">
                          {/* <h1 className="wow fadeInUp" data-wow-delay="0s"> */}
                          <h1>
                            <Text tid="landingTitle" />
                          </h1>
                          {/* <p className="wow fadeInUp" data-wow-delay="0.2s"> */}
                          <p>
                            <Text tid="subTitleOne" />
                          </p>
                          <p>
                            <Text tid="subTitleTwo" />
                          </p>
                          <p>
                            <Text tid="subTitleThree" />
                            {/* <Text tid="subTitleFour" /> */}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="counter-section">
                  <div className="container">
                    <MainMap />
                  </div>
                </div>

                <div className="feature_huge text-center">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12 feature_list">
                        <div className="row promo-content-landing">
                          <div className="col-sm-4">
                            <i className="fa fa-bullhorn" />
                            <h1>
                              <Text tid="landingFeatureOne" />
                            </h1>
                            <p>
                              <Text tid="landingFeatureOneText" />
                            </p>
                          </div>
                          <div className="col-sm-4">
                            <i className="fa fa-map-marker" />
                            <h1>
                              <Text tid="landingFeatureTwo" />
                            </h1>
                            <p>
                              <Text tid="landingFeatureTwoText" />
                            </p>
                          </div>
                          <div className="col-sm-4">
                            <i className="fa fa-smile-o" />
                            <h1>
                              <Text tid="landingFeatureThree" />
                            </h1>
                            <p>
                              <Text tid="landingFeatureThreeText" />
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="download-section-landing">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-7">
                        <div className="download-content-landing">
                          <h2>
                            <Text tid="landingTryForFree" />
                          </h2>
                          <div className="button-group-landing">
                            <div className="moc-download">
                              <img src={iphoneMoc} alt="Download App Store" />
                            </div>
                            <div className="buttons-flex-landing">
                              <div>
                                <a
                                  href="https://apps.apple.com/es/app/bailo-app/id1582308314?l=en"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <img
                                    src={appStoreIcon}
                                    alt="Download App Store"
                                  />
                                </a>
                              </div>
                              <div>
                                <a
                                  href="https://play.google.com/store/apps/details?id=com.bailoapp.bailoapp"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <img
                                    src={playStoreIcon}
                                    alt="Download Play Store"
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="download-mockup wow fadeInUp"
                        data-wow-delay="0ms"
                        data-wow-duration="800ms"
                      ></div>
                    </div>
                  </div>
                </div>
                {/* -- Download Section -- */}

                <div className="description-section no-color text-center">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12 col-sm-12 ">
                        <div className="description-intro">
                          <h1 /* className="wow fadeInUp" data-wow-delay="0s" */
                          >
                            <Text tid="landingStory" />
                          </h1>
                          <p /* className="wow fadeInUp" data-wow-delay="0.2s" */
                          >
                            <Text tid="landingStoryTextOne" />
                          </p>
                          <p /* className="wow fadeInUp" data-wow-delay="0.2s" */
                          >
                            <Text tid="landingStoryTextTwo" />
                          </p>
                          <p /* className="wow fadeInUp" data-wow-delay="0.2s" */
                          >
                            <Text tid="landingStoryTextThree" />
                          </p>
                          <p /* className="wow fadeInUp" data-wow-delay="0.2s" */
                          >
                            <Text tid="landingStoryTextFour" />
                          </p>
                        </div>
                        <div className="row">
                          <div className="col-sm-6">
                            <div
                              // className="table-left wow fadeInUp"
                              className="table-left"
                              // data-wow-delay="0.4s"
                            >
                              <div className="icon">
                                <img src={marceIcon} alt="Icon" />
                              </div>
                              <div className="description-details">
                                <h2>Marcelo Sallito</h2>
                                <p>
                                  <Text tid="landingMarceOne" />
                                  <br className="hidden-xs" />
                                  <Text tid="landingMarceTwo" />
                                  <br className="hidden-xs" />
                                  <Text tid="landingMarceThree" />
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6 ">
                            <div
                              // className="table-right wow fadeInUp"
                              className="table-left"
                              // data-wow-delay="0.6s"
                            >
                              <div className="icon">
                                <img
                                  src={tashIcon}
                                  alt="Icon"
                                  // title="Photo by Sasha Vybornoff, https://www.facebook.com/photo.php?fbid=2422920544388652&set=a.2422919301055443&type=3&theater"
                                  title="Photo by Sasha Vybornoff"
                                />
                                <br className="hidden-xs" />
                                {/* <span>
                                    Photo by{" "}
                                    <a
                                      href="https://www.facebook.com/photo.php?fbid=2422920544388652&set=a.2422919301055443&type=3&theater"
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      Sasha Vybornoff
                                  </a>
                                  </span> */}
                              </div>
                              <div className="description-details">
                                <h2>Tash Tenner</h2>
                                <p>
                                  <Text tid="landingTashOne" />
                                  <br className="hidden-xs" />
                                  <Text tid="landingTashTwo" />
                                  <br className="hidden-xs" />
                                  <Text tid="landingTashThree" />
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="features-section">
                  <div className="f-left h2-w link-w bailo-background">
                    <LandingBlog />
                  </div>
                  <div className="f-left h2-w link-w bailo-background-inverse">
                    <LandingYouTube />
                    {/* <div className="left-content wow fadeInLeft" data-wow-delay="0s">
                      <h2 className="wow fadeInLeft" data-wow-delay="0.1s">We are available for custom work development</h2>
                      <p className="wow fadeInLeft" data-wow-delay="0.2s">
                        We at Datta Able available for custom work development with High end specialized developer.
                      </p>
                      <button className="btn btn-primary btn-action btn-fill wow fadeInLeft" data-wow-delay="0.2s">Click to send query</button>
                    </div> */}
                  </div>
                </div>

                <div className="client-section no-color">
                  <div className="container text-center no-color">
                    <div className="cta-sub text-center no-color">
                      <h1 className="wow fadeInUp" data-wow-delay="0s">
                        <Text tid="commonQuestions" />
                      </h1>
                      <p className="wow fadeInUp" data-wow-delay="0.2s">
                        <Text tid="commonQuestionsTextLanding" />
                        <br className="hidden-xs" />
                        <a
                          href="/knowledge-base"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Text tid="commonQuestions" />
                        </a>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="footer">
                  <div className="container">
                    <div className="col-md-12 text-center">
                      <img src={logo} alt="Bailo.app" />
                      <ul className="footer-menu">
                        <li>
                          <a
                            href="https://www.linkedin.com/company/bailoapp"
                            target="_blank"
                            rel="noreferrer"
                          >
                            LinkedIn
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://twitter.com/BailoApp"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Twitter
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.youtube.com/channel/UC_Ps7_ryCvQ0DVO1NTY9ZIQ/about"
                            target="_blank"
                            rel="noreferrer"
                          >
                            YouTube
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.facebook.com/groups/bailoapp"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Facebook
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.instagram.com/bailo.app/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Instagram
                          </a>
                        </li>

                        <li>
                          <a
                            href="/privacy-policy"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <Text tid="privacyPolicy" />
                          </a>
                        </li>
                        <li>
                          <a
                            href="/terms-of-use"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <Text tid="termsOfUse" />
                          </a>
                        </li>
                      </ul>
                      <div className="footer-text">
                        <p>
                          <Text tid="withLove" />
                        </p>
                      </div>
                      <div className="footer-text">
                        <p>🇦🇷🇩🇪</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Styles>
    </Aux>
  );
};

Landing.propTypes = {
  auth: PropTypes.object.isRequired,
  lang: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  lang: state.lang,
});

export default connect(mapStateToProps)(Landing);
