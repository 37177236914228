import React, { useState, useEffect } from "react";

import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
// import { toast } from "react-toastify";
import { Card } from 'react-bootstrap';
import Aux from "../hoc/_Aux/Aux";
import Text from "./Text";
import Loading from "./Loading";

import '../styles/scss/style.scss';

const PayPal = (props) => {
  const [{ options }, dispatch] = usePayPalScriptReducer();
  const [loading, setLoading] = useState(true);

  const [plan, setPlan] = useState("plan1");
  const [PPType, setPPType] = useState("subscription");

  const [amount, setAmount] = useState("1.00");
  const [orderID, setOrderID] = useState("");
  const [subscriptionID, setSubscriptionID] = useState("");
  const [onApproveMessage, setOnApproveMessage] = useState("");
  const [onErrorMessage, setOnErrorMessage] = useState("");

  const subscriptionOptions = {
    "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
    currency: "EUR",
    intent: "subscription",
    vault: true,
  };

  const orderOptions = {
    "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
    currency: "EUR",
    intent: "capture",
    vault: false,
  }

  useEffect(() => {
    if (options.vault === false) {
      dispatch({
        type: "resetOptions",
        value:
          PPType === "subscription"
            ? subscriptionOptions
            : orderOptions,
      });
    }

    setLoading(false)
  }, [])

  const { dictionary } = props.lang;

  function onPlanChange({ target: { value } }) {
    setPlan(value);
    setAmount("1.00");
    setOrderID("");
    setSubscriptionID("");
    setOnApproveMessage("");
    setOnErrorMessage("");
  }

  function createSubscription(data, actions) {
    if (plan === "plan1") {
      return actions.subscription.create({
        plan_id: process.env.REACT_APP_PAYPAL_PLAN_ID_1
      })
        .then((receivedSubscriptionID) => {
          setSubscriptionID(receivedSubscriptionID);
          return receivedSubscriptionID;
        });
    }

    if (plan === "plan2") {
      return actions.subscription.create(
        {
          plan_id: process.env.REACT_APP_PAYPAL_PLAN_ID_2
        }
      )
        .then((receivedSubscriptionID) => {
          setSubscriptionID(receivedSubscriptionID);
          return receivedSubscriptionID;
        });
    }

    if (plan === "plan3") {
      return actions.subscription.create(
        {
          plan_id: process.env.REACT_APP_PAYPAL_PLAN_ID_3
        }
      )
        .then((receivedSubscriptionID) => {
          setSubscriptionID(receivedSubscriptionID);
          return receivedSubscriptionID;
        });
    }

    if (plan === "plan4") {
      return actions.subscription.create(
        {
          plan_id: process.env.REACT_APP_PAYPAL_PLAN_ID_4

        }
      )
        .then((receivedSubscriptionID) => {
          setSubscriptionID(receivedSubscriptionID);
          return receivedSubscriptionID;
        });
    }

    if (plan === "plan5") {
      return actions.subscription.create(
        {
          plan_id: process.env.REACT_APP_PAYPAL_PLAN_ID_5
        }
      )
        .then((receivedSubscriptionID) => {
          setSubscriptionID(receivedSubscriptionID);
          return receivedSubscriptionID;
        });
    }

    return null;
  }

  function createOrder(
    data, // do not delete!
    actions
  ) {
    return actions.order
      .create({
        purchase_units: [
          {
            amount: {
              value: amount,
            },
          },
        ],
      })
      .then((receivedOrderID) => {
        setOrderID(receivedOrderID);
        return receivedOrderID;
      });
  }

  function onChangeSub({ target: { value } }) {
    setPPType(value);
    setPlan('plan1');

    setAmount("1.00");
    setOrderID("");
    setSubscriptionID("");
    setOnApproveMessage("");
    setOnErrorMessage("");

    dispatch({
      type: "resetOptions",
      value:
        value === "subscription"
          ? subscriptionOptions
          : orderOptions,
    });
  }

  function onApprove(data, actions) { // do not delete!
    if (PPType === "subscription") {
      setOnApproveMessage(
        `${dictionary.thankYou}!`
      );
      // toast.success(`${dictionary.thankYou} ${data.subscriptionID}!`);
    }

    if (PPType === "order") {
      return actions.order.capture()
        .then(() => {
          setOnApproveMessage(
            // `Transaction completed by ${details.payer.name.given_name}!`
            `${dictionary.thankYou}!`
          );
          // toast.success(`${dictionary.thankYou} ${details.payer.name.given_name}!`);
        });
    }

    return null;
  }

  function onError(err) {
    setOnErrorMessage(err.toString());
    // toast.error(`😧 ERROR! ${err.toString()}`);
  }

  function onChange(event) {
    setAmount(event.target.value);
    setOrderID("");
    setSubscriptionID("");
    setOnApproveMessage("");
    setOnErrorMessage("");
  }

  const buttonOptions =
    PPType === "subscription" ? subscriptionOptions : orderOptions;

  return (
    <Aux>
      {loading && <Loading />}
      {!loading &&
        <>
          <Card>
            <Card.Header>
              <Card.Title as="h3">
                <Text tid="paymentTitle" />
              </Card.Title>
              <p>
                <Text tid="paymentTextOne" />
              </p>
              <p>
                <Text tid="paymentTextTwo" />
              </p>
              <h5>
                <Text tid="paymentTextThree" />
              </h5>
              <ul>
                <li>
                  <Text tid="paymentTextFour" />
                </li>
                <li>
                  <Text tid="paymentTextFive" />
                </li>
              </ul>
              <h5
                style={{ marginTop: '16px', marginBottom: '16px' }}
              >
                <Text tid="paymentTextSix" />
              </h5>
              <h4
                // style={{ marginTop: '16px', marginBottom: '16px' }}
                className="text-center"
              >
                <Text tid="paymentTextSeven" />
              </h4>
            </Card.Header>
          </Card>
          <div
            // style={{ alignItems: 'center' }}
            className="text-center"
          >
            {onApproveMessage !== "" || orderID !== "" || subscriptionID !== "" || onErrorMessage !== "" ?
              <Card>
                <Card.Header>
                  {onApproveMessage !== "" ?
                    <h5
                      className="text-center"
                    >
                      {onApproveMessage}
                    </h5> : <></>}
                  {orderID !== "" ?
                    <h5
                      className="text-center"
                    >
                      <Text tid="refNumber" />
                      {orderID}
                    </h5> : <></>}
                  {subscriptionID !== "" ?
                    <h5
                      className="text-center"
                    >
                      <Text tid="refNumber" />
                      {subscriptionID}
                    </h5> : <></>}
                  {onErrorMessage !== "" ?
                    <h5
                      className="text-center"
                    >
                      {onErrorMessage}
                    </h5> : <></>}
                </Card.Header>
              </Card> :
              <></>
            }
            <form>
              <label
                style={{ display: 'inline-block' }}
              >
                <input
                  defaultChecked
                  onChange={onChangeSub}
                  type="radio"
                  name="type"
                  value="subscription"
                />
                <Text tid="subscription" />
              </label>
              <br />
              <label
                style={{ display: 'inline-block' }}
              >
                <input
                  onChange={onChangeSub}
                  type="radio"
                  name="type"
                  value="order"
                />
                <Text tid="order" />
              </label>
            </form>
            <hr />
            {PPType === "subscription" ? (
              <div>
                <form>
                  <select
                    id="plan"
                    name="plan"
                    onChange={onPlanChange}
                    value={plan}
                    style={{ marginBottom: '16px' }}
                  >
                    <option value="plan1">
                      {dictionary.plan1}
                    </option>
                    <option value="plan2">
                      {dictionary.plan2}
                    </option>
                    <option value="plan3">
                      {dictionary.plan3}
                    </option>
                    <option value="plan4">
                      {dictionary.plan4}
                    </option>
                    <option value="plan5">
                      {dictionary.plan5}
                    </option>
                  </select>
                </form>
                {/* <div style={{ maxWidth: "80px" }}> */}
                {/* <div
                    style={{
                      zIndex: 1,
                    }}
                  > */}
                {/* <PayPalButtons
              createSubscription={createSubscription}
              onApprove={onApprove}
              onError={onError}
              forceReRender={[plan]}
              style={{
                label: "subscribe",
                layout: "vertical",
                height: 55,
                shape: "pill",
              }}
            /> */}
                {/* </div> */}
                {/* </div> */}
              </div>
            ) : (
              <div>
                {/* <div style={{ minHeight: "300px" }}> */}
                {/* <table className="table" style={{ maxWidth: "400px" }}>
                    <tbody>
                      <tr
                        // className="text-center"
                        style={{ display: 'inline-block' }}
                      >
                        <th> */}
                <label
                  htmlFor="amount"
                  style={{ display: 'inline-block' }}
                >
                  <Text tid="amount" />
                </label>
                <br />
                {/* </th>
                        <td> */}
                <input
                  onChange={onChange}
                  name="amount"
                  id="amount"
                  value={amount}
                  style={{ marginBottom: '16px' }}
                >
                </input>
                {/* </td>
                      </tr> */}
                {/* <tr>
                  <th>Order ID:</th>
                  <td>{orderID || "unknown"}</td>
                </tr> */}
                {/* <tr>
                  <th>On Approve Message: </th>
                  <td data-testid="message">{onApproveMessage}</td>
                </tr>
                <tr>
                  <th>On Error Message: </th>
                  <td data-testid="error">{onErrorMessage}</td>
                </tr> */}
                {/* </tbody>
                  </table> */}
                {/* <div
                      style={{
                        zIndex: 1,
                      }}
                    > */}
                {/* <PayPalButtons
              createOrder={createOrder}
              onApprove={onApprove}
              onError={onError}
              forceReRender={[amount]}
              style={{
                layout: "vertical",
                height: 55,
                shape: "pill",
              }}
            /> */}
                {/* </div> */}
                {/* </div> */}
              </div>
            )}

            <PayPalButtons
              createSubscription={PPType === "subscription" ? createSubscription : null}
              createOrder={PPType === "order" ? createOrder : null}
              onApprove={onApprove}
              onError={onError}
              forceReRender={PPType === "subscription" ? [plan] : [amount]}
              style={{
                // label: "subscribe",
                layout: "vertical",
                height: 55,
                shape: "pill",
              }}
              {...buttonOptions}
            />

          </div>
        </>
      }
    </Aux >
  );
};

PayPal.propTypes = {
  auth: PropTypes.object.isRequired,
  lang: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  lang: state.lang,
});

export default connect(
  mapStateToProps)(PayPal);