/* eslint-disable class-methods-use-this */
import axios from "axios";

class PostService {
  // async getAllPosts() {
  //   const { data: posts } = await axios
  //     .get(`${process.env.REACT_APP_BACKEND_URI}/api/posts`,
  //       {},
  //       {
  //         headers: {
  //           Authorization: `${localStorage.jwtToken}`,
  //         },
  //       },
  //     );
  //   return posts;
  // }

  // async getPostById(id) {
  //   const { data: post } = await axios
  //     .get(`${process.env.REACT_APP_BACKEND_URI}/api/posts/${id}`,
  //       {},
  //       {
  //         headers: {
  //           Authorization: `${localStorage.jwtToken}`,
  //         },
  //       },
  //     );
  //   return post;
  // }

  async getMediumFeed() {
    const { data: post } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URI}/api/posts/medium-feed`,
      {},
      {
        // headers: {
        //   Authorization: `${localStorage.jwtToken}`,
        // },
      }
    );
    return post;
  }

  async sendCity(city) {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_URI}/api/posts/city`,
      {
        city,
      },
      {
        // headers: {
        //   Authorization: `${localStorage.jwtToken}`,
        // },
      }
    );
    return data;
  }

  async sendBailoDot(bailoDotTitle) {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_URI}/api/posts/mapdot`,
      {
        bailoDotTitle,
      },
      {
        // headers: {
        //   Authorization: `${localStorage.jwtToken}`,
        // },
      }
    );
    return data;
  }

  async sendMessageToFacebook(meeting) {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_URI}/api/posts/facebook`,
      {
        meeting,
      },
      {
        headers: {
          Authorization: `${localStorage.jwtToken}`,
        },
      }
    );
    return data;
  }

  async getPodcastByName(search) {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URI}/api/posts/radio-search`,
      {
        search,
      },
      {
        headers: {
          Authorization: `${localStorage.jwtToken}`,
        },
      }
    );
    return data;
  }

  async getPodcastEpisodes(search) {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URI}/api/posts/get-radio-episodes`,
      {
        search,
      }
      // {
      //   headers: {
      //     Authorization: `${localStorage.jwtToken}`,
      //   },
      // }
    );
    return data;
  }

  async playEpisode(episodeId) {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_URI}/api/posts/play-episode`,
      {
        episodeId,
      },
      {
        headers: {
          Authorization: `${localStorage.jwtToken}`,
        },
      }
    );
    return data;
  }
}

const postService = new PostService();

export default postService;
