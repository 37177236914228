/* eslint-disable prefer-template */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactGA from "react-ga";
import { Link } from "react-router-dom";
import Aux from "../../hoc/_Aux/Aux";
import authService from "../../services/authService";
import Loading from "../../components/Loading";
import Text from "../../components/Text";

ReactGA.initialize(process.env.REACT_APP_GA_ID_TRACKING);

class Confirm extends Component {
  // A bit of state to give the user feedback while their email
  // address is being confirmed on the User model on the server.
  state = {
    confirming: true,
  };

  // When the component mounts the mongo id for the user is pulled  from the
  // params in React Router. This id is then sent to the server to confirm that
  // the user has clicked on the link in the email. The link in the email will
  // look something like this:
  //
  // http://localhost:3000/confirm/5c40d7607d259400989a9d42
  //
  // where 5c40d...a9d42 is the unique id created by Mongo

  async componentDidMount() {
    try {
      const { id } = this.props.match.params;

      await authService
        .confirmMail(id)
        .then((res) => {
          this.setState({ confirming: false });
          switch (res.value) {
            case "confirmed":
              toast.success(`🚀${res.msg}!`);
              break;
            case "confirm":
              toast.info(`📩${res.msg}!`);
              break;
            case "resend":
              toast.info(`🔁${res.msg}!`);
              break;
            case "couldNotFind":
              toast.warning(`❗️${res.msg}!`);
              break;
            case "alreadyConfirmed":
              toast.info(`👍${res.msg}!`);
              break;

            default:
              toast.error("😧 ERROR!");
              break;
          }
        })
        .catch((error) => {
          toast.error("😧 ERROR!");
          console.log(`Auth confirm error msg: ${error}`);

          ReactGA.exception({
            description: `Auth confirm error msg: ${error}`,
            fatal: true,
          });
          // this.props.handleSetMessage(
          //   {
          //     typeMessage: 'error',
          //     message: `${error.response.data.error._message}`,
          //   },
          // );
        });
      // const roles = await roleService.getAllRoleNames();
      // this.setState({
      //   // roles,
      //   loading: false,
      // });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      // this.setState({
      //   loading: false,
      // });
    }
  }

  // While the email address is being confirmed on the server a spinner is
  // shown that gives visual feedback. Once the email has been confirmed the
  // spinner is stopped and turned into a button that takes the user back to the
  // <Landing > component so they can confirm another email address.
  render() {
    return (
      <Aux>
        <div className="auth-wrapper">
          <div className="auth-content">
            <div className="card-body text-center">
              {this.state.confirming ? (
                <Loading />
              ) : (
                <div className="description-section no-color text-center">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12 col-sm-12 ">
                        <div className="description-intro">
                          <h1 /* className="wow fadeInUp" data-wow-delay="0s" */
                          >
                            <Text tid="confirmTextOne" />
                          </h1>
                          <p className="wow fadeInUp" data-wow-delay="0.2s">
                            <Text tid="confirmTextTwo" />
                          </p>

                          <div className="wow fadeInUp" data-wow-delay="0.2s">


                          </div>
                          <div>
                            <a
                              className="btn btn-outline-primary"
                              href="bailoapp://"
                              target="_blank"
                              rel="noreferrer"
                            >
                              Open app
                            </a>
                          </div>

                          <div className="buttons-flex-landing">

                            <div>
                              <a
                                href="https://apps.apple.com/es/app/bailo-app/id1582308314?l=en"
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img
                                  alt="Download App Store"
                                  src="https://res.cloudinary.com/dklijqv8g/image/upload/v1633180218/appstore_ewn0kb.png"
                                />
                              </a>
                            </div>

                            <div>
                              <a
                                href="https://play.google.com/store/apps/details?id=com.bailoapp.bailoapp"
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img
                                  alt="Download Play Store"
                                  src="https://res.cloudinary.com/dklijqv8g/image/upload/v1633180218/playstore_dnzvaw.png"
                                />
                              </a>
                            </div>
                          </div>
                          <div className="wow fadeInUp" data-wow-delay="0.2s">
                            <hr />
                            <p>or</p>
                            <p>
                              <Text tid="confirmTextFive" />
                            </p>
                          </div>
                          <div>
                            <a
                              className="btn btn-outline-primary"
                              href="https://bailo.app/login"
                              target="_blank"
                              rel="noreferrer"
                            >
                              Go to website
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Aux>
    );
  }
}

Confirm.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps)(Confirm);
