import eng from './English';
import spa from './Spanish';

export const dictionaryList = {
  eng,
  spa
};

export const languageOptions = [
  { id: 'eng', text: 'English' },
  { id: 'spa', text: 'Español' }
];