const spa = {
  aboutMe: "Para mas info sobre el mi.",
  profile: "Perfil",
  hiAdmin: "Hola Admin",
  username: "Usuario",
  password: "contraseña",
  login: "ingresar",
  loginCap: "Ingresar",
  signup: "registrarse",
  signupCap: "Registrarse",
  loginTitle: "Inicia tu sesión",
  signupTitle: "¡Regístrate y empieza a bailar!",
  email: "Correo electrónico",
  contactUs: "Contacto",
  privacyPolicy: "Políticas de privacidad",
  termsOfUse: "Términos de uso",
  copyright: "Todos los derechos reservados © 2017-2021 Bailo.app",
  followUs: "Sígannos en: fb, twitter, instagram",
  following: "Siguiendo",
  iLike: "me gusta",
  aboutUs: "Sobre nosotros",
  faq: "Preguntas frecuentes",
  downloadApp: "Descarguen la APP",
  withLove: "Hecho con ❤️ en Barcelona",
  homepage: "inicio",
  map: "Mapa",
  myBailo: "Mi Bailo",
  logout: "salir",
  titleMapdot: "título",
  ownerMapdot: "organizador",
  addMapdot: "Añadir Bailo Dot",
  myBailoDotsFollow: "Los Bailo Dots que sigo",
  myBailoDotsLead: "Los Bailo Dots que organizo",
  myMeetups: "Reuniones a las que voy",
  dance: "baile:",
  category: "categoría",
  title: "título",
  description: "descripción",
  address: "dirección:",
  addressPlus: "dirección info adicional:",
  postalCode: "código postal:",
  city: "ciudad:",
  country: "país:",
  phoneNr: "teléfono:",
  emailMapdot: "correo electrónico profesional:",
  website: "página web:",
  day: "día:",
  frequency: "frecuencia:",
  dateStart: "fecha - inicio",
  dateEnd: "fecha - fin",
  timeStart: "hora - inicio:",
  timeEnd: "hora - fin:",
  price: "precio:",
  buttonAdd: "Añadir",
  venue: "milonga",
  practica: "práctica",
  tango: "tango",
  monday: "lunes",
  tuesday: "martes",
  wednesday: "miércoles",
  thursday: "jueves",
  friday: "viernes",
  saturday: "sábado",
  sunday: "domingo",
  once: "una vez",
  weekly: "semanal",
  fortnightly: "quincenal",
  monthly: "mensual",
  editMapdot: "editar",
  editBailoDot: "Editar Bailo Dot",
  buttonUpdate: "actualizar",
  buttonDelete: "borrar",
  followLink: "siguir bailo-dots",
  leadLink: "llevar bailo-dots",
  addLink: "añadir bailo-dots",
  searchFor: "Filtrar por ciudad",
  searchForMilongaName: "Buscar Bailo Dots",
  admin: "Admin",
  adminAllBailoDots: "bailo-dots",
  adminPendingBailoDots: "bailo-dots pendientes",
  adminUsers: "Usuarios",
  customerChat: "Chat con usuarios",
  danceRole: "rol en el baile",
  statusUser: "estado del usuario",
  all: "todos",
  approved: "aprobado",
  rejected: "rechazado",
  onhold: "en espera",
  pending: "pendiente",
  userProfile: "perfil de usuario",
  numberOfLikes: "seguidores",
  activeSince: "activo desde:",
  musicStyle: "estilo de música:",
  additionalInfo: "información adicional",
  statusMapdot: "estado",
  imageMapdot: "imagen",
  yes: "sí",
  no: "no",
  realOwner: "¿Es tu Bailo Dot?",
  useReverseGeo: "usa mi ubicación",
  locationAnswer: "ubicación del bailo-dot guardado",
  userLocation: "Ubicación",
  createdBy: "creado por",
  latitude: "latitud",
  longitude: "longitud",
  stars: "estrellas",
  note: "nota",
  date: "fecha",
  meetups: "Reunión de eventos",
  addMeetUp: "añadir una reunión",
  createdMeetups: "reunión creada",
  attendMeetups: "asistir a una reunión",
  editMeetUp: "editar reunión",
  userAbout: "Sobre mi",
  userAboutMe: "Sobre mi",
  personalInformation: "Información Personal",
  unfriend: "Eliminar amistad",
  friends: "Amigos",
  block: "Bloquear",
  unblock: "Desbloquear",
  cancelFriendship: "Cancelar solicitud",
  acceptFriendship: "Acceptar solicitud",
  denyFriendship: "Rechazar solicitud",
  social: "Social",
  shortInfo: "Info",
  reviews: "Reseñas",
  noReviews: "Aún sin comentarios",
  addReview: "Añandir reseña",
  goGoogle: "Como llegar allí",
  capacity: "Aforo disponible",
  booking: "Reservar",
  dateOfMeeting: "fecha de la reunión",
  hasComments: "comentarios",
  participants: "Participantes",
  editProfile: "Editar perfil",
  danceRoleLeader: "líder",
  danceRoleFollower: "seguidor/a",
  danceRoleBoth: "ambos",
  new: "Nuevo",
  repeat: "Repetir",
  settings: "Ajustes",
  editPassword: "Editar contraseña",
  landingTitle: "Bailo.app - buscador de sitios de tango",
  subTitleOne: "Ya hay más que 1000 milongas por el mundo.",
  subTitleTwo:
    "Usa el mapa aquí abajo o inicia tu sesión para ver los detalles de cada milonga.",
  subTitleThree: "Puedes registrar tu propia milonga.",
  landingFeatureOne: "Siempre nuevas funcionalidades",
  landingFeatureTwo: "Comunidad tanguera en crecimiento",
  landingFeatureThree: "Fácil de usar & gratis",
  landingFeatureOneText:
    "La mejora contínua es importante para nosotros y agradecemos tus comentarios.",
  landingFeatureTwoText:
    "¿Falta una milonga en el mapa? ¡Agrégala o avísanos y lo hacemos por vos!",
  landingFeatureThreeText:
    "Encuentra tu milonga rápidamente, sea desde casa o durante tus viajes.",
  landingStory: "Nuestra historia",
  landingStoryTextOne:
    "Nuestro propósito es crear una comunidad internacional de tango con milongas fáciles de encontrar en todo el mundo.",
  landingStoryTextTwo:
    "Bailo.app nació en 2017 bajo el nombre de Tango Maps como un simple mapa con nuestras milongas favoritas, principalmente en Barcelona, Nueva Zelanda, Israel, Italia y Córdoba, Argentina. Ambos somos amantes del tango y (qué sorpresa) nos conocimos bailando tango en Barcelona.",
  landingStoryTextThree:
    "Hasta hoy, hemos recibido comentarios positivos de amantes del tango de todo el mundo a quienes les gusta viajar, conocer a otros tangueras y tangueros y disfrutar de algunos bailes de tango. Bailo.app es más que una simple lista de lugares de tango, Bailo.app es una comunidad online en crecimiento.",
  landingStoryTextFour:
    "Bailo.app disfruta del tango con sus tradiciones y también apoya nuevos movimientos, como la música de tango alternativo y el intercambio de roles de género/baile.",
  landingMarceOne: "Amante del tango desde 2012",
  landingMarceTwo: "De Argentina",
  landingMarceThree: "Analista de Sistemas & Desarrollador Web",
  landingTashOne: "Amante del tango desde febrero 2006",
  landingTashTwo: "De Alemania",
  landingTashThree: "Lingüista & Web Developer",
  typeLetter: "escribe una letra",
  noResultsfound: "no hay resultados",
  contactUsText:
    "¿Tienes algunas preguntas o comentarios? Contáctanos: bailo.app@gmail.com",
  bailoDots: "Bailo Dots",
  follow: "Siguiendo",
  leadBailoDots: "Gestión de Bailo Dots",
  leadMeetups: "Gestión de reuniones",
  addBailoDot: "Añadir Bailo Dot",
  addMeetup: "Añadir Reunión",
  haveAnAccount: "Ya estas registrado?",
  keenTo: "Le gustaría",
  backTo: "Regregar al",
  class: "clase",
  school: "escuela",
  teacher: "profesor/a",
  acceptTUPPOne: "Yo acepto los",
  acceptTUPPTwo: "y",
  help: "Ayuda",
  commonQuestions: "Preguntas frecuentes",
  bailoYouTube: "Tango Cortometrajes",
  bailoYouTubeText:
    "En búsqueda de los mejores cortometrajes que hablan del tango. Los derechos de cada video se mantiene con la persona que subió el video. Bailo.app simplemente quiere promover el mundo del tango.",
  bailoBlog: "Bailo.app Blog",
  bailoBlogText: "¡Mira nuestro último post en el Blog!:",
  bailoBlogHomepageTitle: "Página principal del Blog:",
  commonQuestionsTextLanding:
    "¿Cómo añado una milonga al mapa? ¿Qué es un Bailo Dot? Esta pregunta y otras serán respondidas en nuestra sección de preguntas frecuentes. Por si hay otras dudas, avísanos vía mail:",
  comments: "Comentarios",
  addComment: "Añadir comentario",
  userCommentUpdate: "¿Quieres actualizar tu comentario?",
  comment: "comentario",
  finalDot: ".",
  notionLink: "Notion",
  sendPush: "Enviar Push",
  allSentPush: "Historico Push",
  sureSendPush: "Estás seguro que quieres enviar este push?",
  updateComment: "Editar tu comentario",
  cancelButton: "cancelar",
  deleteComment: "Borrar tu comentario",
  cancel: "cancelar",
  spotsLeft: "plazas restantes",
  thankYou: "¡Muchas gracias",
  subscription: "Donación mensual en €",
  order: "Donación puntual en €",
  amount: "valor",
  plan1: "1€ mensualmente - La Yumba",
  plan2: "2€ mensualmente - Libertango",
  plan3: "5€ mensualmente - Farol",
  plan4: "10€ mensualmente - Poema",
  plan5: "15€ mensualmente - La cumparsita",
  paymentTitle:
    "Pago 'a la gorra' - el costo es lo que sientas lo que vale el servicio. Desde ya ¡muchas gracias!",
  paymentTextOne:
    "Bailo.app ​​es una organización sin fines de lucro y todos los pagos monetarios que se realizan, se destinan a construir y mejorar este proyecto. Bailo.app brinda un servicio a la comunidad del tango desde su creación en 2017. Nosotros somos un pequeño grupo técnico y amantes del tango, fundada por Marcelo y Tash, y que trabajamos para Bailo.app en nuestro tiempo libre, sin embargo, en nuestro corazón y en nuestra mente estamos completamente dedicados a expandir este sitio web y también estamos ansiosos por comenzar con una aplicación nativa. Es posible que hayas notado que en nuestro sitio web no mostramos ningún anuncio, por lo que tu contribución a voluntad, realmente marcaría la diferencia en el crecimiento del sitio web.",
  paymentTextTwo:
    "Tu pago a voluntad (o a la gorra), se utilizará para mantenimiento, mejoras y creación de funcionalidades adicionales que harán más fácil la administración y el uso de la plataforma de Bailo.app. Esto incluye cubrir los costos de nuestro servidor, hosting, base de datos, hosting de imágenes, logística y otros gastos operativos.",
  paymentTextThree: "Elige tu opción para dar:",
  paymentTextFour: "Gift único, tú mismo eliges la cantidad",
  paymentTextFive: "Gift mensual, hay 5 planes para elegir",
  paymentTextSix:
    "Elige entre un gift mensual o único, haz clic en el enlace de PayPal o en el enlace para un pago seguro con tarjeta de crédito. Recuerda que el costo es lo que sientas lo que vale el servicio.",
  paymentTextSeven: "THANK YOU. GRACIAS. DANKE. MERCI.",
  empty: "",
  saveChanges: "Guardar cambios",
  closeButton: "Cerrar",
  refNumber: "Tu número de referencia: ",
  deleteMyUser: "Quitar mi usuario",
  publicName: "nombre público",
  dateOnce: "fecha:",
  termsOfClass: "términos de clase:",
  classLevel: "nivel:",
  offersOnlineLesson: "classes online:",
  offersPrivateLesson: "classes privadas:",
  infoAddBailoDot:
    "Ni bien te aceptemos el Bailo Dot, podrás interconectarlo con otros Bailo Dots.",
  addSchool: "Conecta con tu escuela",
  addNoSchool: "No vincular escuela / desvincular",
  noSchoolFound: "no hay escuelas",
  linkedSchool: "Escuela vinculada",
  noSchoolLinked: "sin escuela vinculada",
  addressAprox: "dirección aproximada",
  noDescriptionYet: "sin descripción aún",
  connections: "Conexiones con otros Bailo Dots",
  addTeacher: "Conecta con un/a profesor/a",
  addNoTeacher: "No vincular profesor/a / desvincular",
  noTeacherFound: "no hay profesores",
  linkedTeacher: "Profesor/a vinculado/a",
  noTeacherLinked: "sin profesor/a vinculado/a",
  share: "compartir",
  sureDelete: "¿Estás seguro que quieres borrar tu Bailo Dot?",
  leaveThisMeetup: "abandonar este Meetup",
  noNote: "sin nota",
  commentsGiven: "Comentarios dados",
  updateImg: "Subir imagen",
  dropImg: "Arrastra la imagen aquí",
  imgUpload: "imagen_subida",
  newMeetupCreated: "ha creado un nuevo evento para el",
  chatNotFound: "No se encontró el chat",
  registerWithAdminTash:
    "Por favor, loggueate con el Admin Tash para poder responder a este chat.",
  radio: "radio",
  musician: "músico",
  belongsToRadioStation: "estación de radio:",
  belongsToPodcastPlatform: "platforma de podcast:",
  languageSpokenRadio: "idioma que se habla:",
  radioWebsite: "página web de la radio",
  linkWebsite: "enlace",
  buttonAddFriend: "Añadir a amigos",
  friendRequests: "Solucitudes de amistad",
  reportInfo: "Reportar un error o confirmar los datos",
  reportErrorDescription: "Descripción del error",
  reportLastConfirmation: "Última confirmación",
  reportNoConfirmation: "Sin confirmación",
  report: "Reportar",
  confirm: "Confirmar",
  edit: "Editar",
  reportsTitle: "Reportes",
  confirmTextOne: "Confirmado 🎉",
  confirmTextTwo: "Ahora podrás ingresar vía la app o la página web:",
  confirmTextThree: "Descargar del Apple Store",
  confirmTextFour: "Descargar del Google Play",
  confirmTextFive: "Logguearse vía la página web",
  landingTryForFree: "Prueba gratis Bailo.app!",
  sending: "Sending",
  send: "Enviar",
  body: "body",
  googleSignup: "Registrarse con Google",
  googleLogin: "Ingresar con Google",
  facebookSignup: "Registrarse con Facebook",
  facebookLogin: "Ingresar con Facebook",
  whatIsHot: "Lo destacado",
  dj: "dj",
  singer: "cantante",
  orchestra: "orquesta",
  bandoneonist: "bandoneonista",
  guitarist: "guitarrista",
  composer: "compositor",
  quartet: "cuarteto",
  band: "banda",
  musicianType: "músico - categoría",
  state: "provincia",
  county: "county",
  errorUsernameEmpty: "username es requerido",
  errorEmailEmpty: "email es requerido",
  errorEmailInvalid: "email inválido",
  errorPasswordInvalid: "password inválido",
  errorPasswordisLowercase: "agregar una minúscula",
  errorPasswordisUppercase: "agregar una Mayúscula",
  errorPasswordSpecialChars: "agregar caracter especial: #?!@$%^&*-",
  errorPasswordLength: "min 8 caracteres",
  errorPassword2Empty: "confirmar contraseña es requerido",
  errorPassword2Equals: "las contraseñas son distintas",
  errorUsernameExist: "Username ya existe",
  errorEmailExist: "Email ya existe",
  passwordErrorInfo: "Información para contraseña",
  passwordInfo:
    "Contraseña: Por lo menos una letra en mayúscula (menos la ñ), por lo menos una letra en minúscula (menos la ñ), por lo menos un dígito, por lo menos un carácter especial (#?!@$%^&*-), longitud mínima: 8",
};

export default spa;
