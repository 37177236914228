import { connect } from "react-redux";
import { PropTypes } from "prop-types";

const Text = (props) => {
  const {
    dictionary
  } = props.lang;

  return dictionary[props.tid];
};

Text.propTypes = {
  lang: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  lang: state.lang
});

export default connect(mapStateToProps)(Text);