import { combineReducers } from "redux";

import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import layoutReducer from "./layoutReducer";
import languageReducer from "./languageReducer";
import questionReducer from "./questionReducer";

export default combineReducers({
    auth: authReducer,
    errors: errorReducer,
    layout: layoutReducer,
    lang: languageReducer,
    question: questionReducer,
});