/* eslint-disable no-console */
import React, { Component } from "react";

import { PropTypes } from "prop-types";
import { connect } from "react-redux";
// import { Link } from "react-router-dom";
// import { Col, Row } from 'react-bootstrap';

import Aux from "../../hoc/_Aux/Aux";
// import Breadcrumb from "../../components/Breadcrumb";
import { Styles } from "./LandingStyles";

import Loading from "../../components/Loading";
import Text from "../../components/Text";

class LandingYouTube extends Component {
  state = {
    loading: true,
  };

  async componentDidMount() {
    try {
      this.setState({
        loading: false,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      this.setState({
        loading: false,
      });
    }
  }

  render() {
    const { loading } = this.state;

    return (
      <Aux>
        <Styles>
          {loading && <Loading />}
          {!loading && (
            <div className="p-w">
              <h2>
                <Text tid="bailoYouTube" />
              </h2>
              <p>
                <Text tid="bailoYouTubeText" />
              </p>
              <div className="embed-responsive embed-responsive-16by9">
                {/* <iframe title="Responsive Item" className="embed-responsive-item" src="https://www.youtube.com/embed/ilLU5vxhLYA?rel=0" /> */}
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/videoseries?list=PLXiGf_zwgDB1qAYSTOKOTlbcuEqiVTJ6-"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>

              {/* <h2>
                <Text tid="bailoBlog" />
              </h2>
              <p>
                <Text tid="bailoBlogText" />
                <br className="hidden-xs" />
                <Link
                  to={`blog/${title}`}
                >
                  {title}
                </Link>
              </p>
              <p>
                <Text tid="bailoBlogHomepageTitle" />
                <br className="hidden-xs" />
                <Link
                  to={`blog`}
                >
                  <Text tid="bailoBlog" />
                </Link>
              </p> */}
            </div>
          )}
        </Styles>
      </Aux>
    );
  }
}

LandingYouTube.propTypes = {
  auth: PropTypes.object.isRequired,
  lang: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  lang: state.lang,
});

export default connect(mapStateToProps)(LandingYouTube);
