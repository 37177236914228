import {
  CHANGE_QUESTION
} from "../actions/types";

const initialState = {
  questionId: null,
  index: null,
  deviceId: null,
  conversation: [],
};

// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  switch (action.type) {
    case CHANGE_QUESTION:
      return {
        ...state,
        questionId: action.questionId,
        index: action.index,
        deviceId: action.deviceId,
        conversation: action.conversation,
      };
    default:
      return state;
  }
};