/* eslint-disable class-methods-use-this */
import React from "react";

import { Row, Col } from "react-bootstrap";
import Aux from "../../hoc/_Aux/Aux";
import "../../styles/scss/style.scss";

class TermsOfUseEsp extends React.Component {
  render() {
    return (
      <Aux>
        <Row>
          <Col>
            <div className="card-body">
              <h1> Términos y condiciones </h1>
              <p> Última actualización: 18 de junio de 2021 </p>
              <p>
                {" "}
                Lea estos términos y condiciones detenidamente antes de utilizar
                Nuestro servicio.{" "}
              </p>
              <h1> Interpretación y definiciones </h1>
              <h2> Interpretación </h2>
              <p>
                {" "}
                Las palabras cuya letra inicial está en mayúscula tienen
                significados definidos bajo las siguientes condiciones. Las
                siguientes definiciones tendrán el mismo significado
                independientemente de si aparecen en singular o en plural.{" "}
              </p>
              <h2> Definiciones </h2>
              <p> A los efectos de estos Términos y condiciones: </p>
              <ul>
                <li>
                  <p>
                    {" "}
                    <strong> Afiliado </strong> significa una entidad que
                    controla, está controlada por o está bajo control común con
                    una parte, donde & quot; control & quot; significa la
                    propiedad del 50% o más de las acciones, participación en el
                    capital social u otros valores con derecho a voto para la
                    elección de directores u otra autoridad de gestión.{" "}
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong> Cuenta </strong> significa una cuenta única creada
                    para que usted acceda a nuestro Servicio o partes de nuestro
                    Servicio.{" "}
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong> País </strong> se refiere a: España{" "}
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong> Compañía </strong> (referida como & quot; la
                    Compañía & quot ;, & quot; Nosotros & quot;, & quot; Nos &
                    quot; o & quot; Nuestro & quot; en este Acuerdo) se refiere
                    a Bailo.app.{" "}
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong> Contenido </strong> se refiere a contenido como
                    texto, imágenes u otra información que Usted puede publicar,
                    cargar, vincular o poner a disposición de usted,
                    independientemente de la forma de ese contenido.{" "}
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong> Dispositivo </strong> se refiere a cualquier
                    dispositivo que pueda acceder al Servicio, como una
                    computadora, un teléfono celular o una tableta digital.{" "}
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong> Comentarios </strong> se refiere a comentarios,
                    innovaciones o sugerencias enviadas por Usted con respecto a
                    los atributos, el rendimiento o las características de
                    nuestro Servicio.{" "}
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong> Servicio </strong> se refiere al sitio web.{" "}
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong> Términos y condiciones </strong> (también
                    denominados & quot; Términos & quot;) se refieren a estos
                    Términos y condiciones que forman el acuerdo completo entre
                    Usted y la Compañía con respecto al uso del Servicio.{" "}
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong>
                      {" "}
                      Servicio de redes sociales de terceros{" "}
                    </strong>{" "}
                    significa cualquier servicio o contenido (incluidos datos,
                    información, productos o servicios) proporcionado por un
                    tercero que puede ser mostrado, incluido o puesto a
                    disposición por el Servicio.{" "}
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong> Sitio web </strong> se refiere a Bailo.app,
                    accesible desde{" "}
                    <a
                      href="https://bailo.app/"
                      rel="noreferrer external nofollow noopener"
                      target="_blank"
                    >
                      {" "}
                      https: / /bailo.app/{" "}
                    </a>{" "}
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong> Usted </strong> se refiere a la persona que accede
                    o utiliza el Servicio, o la empresa u otra entidad legal en
                    nombre de la cual dicha persona accede o utiliza el
                    Servicio, según corresponda.{" "}
                  </p>
                </li>
              </ul>
              <h1> Reconocimiento </h1>
              <p>
                {" "}
                Estos son los Términos y Condiciones que rigen el uso de este
                Servicio y el acuerdo que opera entre Usted y la Compañía. Estos
                Términos y Condiciones establecen los derechos y obligaciones de
                todos los usuarios con respecto al uso del Servicio.{" "}
              </p>
              <p>
                {" "}
                Su acceso y uso del Servicio está condicionado a su aceptación y
                cumplimiento de estos Términos y Condiciones. Estos Términos y
                condiciones se aplican a todos los visitantes, usuarios y otras
                personas que acceden o utilizan el Servicio.{" "}
              </p>
              <p>
                {" "}
                Al acceder o utilizar el Servicio, acepta estar sujeto a estos
                Términos y condiciones. Si no está de acuerdo con alguna parte
                de estos Términos y condiciones, no podrá acceder al Servicio.{" "}
              </p>
              <p>
                {" "}
                Usted declara que es mayor de 18 años. La Compañía no permite
                que menores de 18 años utilicen el Servicio.{" "}
              </p>
              <p>
                {" "}
                Su acceso y uso del Servicio también está condicionado a Su
                aceptación y cumplimiento de la Política de privacidad de la
                Compañía. Nuestra Política de privacidad describe Nuestras
                políticas y procedimientos sobre la recopilación, uso y
                divulgación de Su información personal cuando utiliza la
                Aplicación o el Sitio web y le informa sobre Sus derechos de
                privacidad y cómo la ley lo protege. Lea nuestra Política de
                privacidad detenidamente antes de utilizar nuestro servicio.{" "}
              </p>
              <h1> Cuentas de usuario </h1>
              <p>
                {" "}
                Cuando crea una cuenta con Nosotros, debe proporcionarnos
                información precisa, completa y actualizada en todo momento. El
                no hacerlo constituye una violación de los Términos, que puede
                resultar en la cancelación inmediata de Su cuenta en Nuestro
                Servicio.{" "}
              </p>
              <p>
                {" "}
                Usted es responsable de proteger la contraseña que utiliza para
                acceder al Servicio y de cualquier actividad o acción bajo Su
                contraseña, ya sea que Su contraseña sea de Nuestro Servicio o
                de un Servicio de redes sociales de terceros.{" "}
              </p>
              <p>
                {" "}
                Acepta no revelar su contraseña a ningún tercero. Debe
                notificarnos inmediatamente después de tener conocimiento de
                cualquier violación de seguridad o uso no autorizado de su
                cuenta.{" "}
              </p>
              <p>
                {" "}
                No puede usar como nombre de usuario el nombre de otra persona o
                entidad o que no esté legalmente disponible para su uso, un
                nombre o marca comercial que esté sujeta a cualquier derecho de
                otra persona o entidad que no sea usted sin la debida
                autorización, o nombre que de otro modo sea ofensivo, vulgar u
                obsceno.{" "}
              </p>
              <h1> Contenido </h1>
              <h2> Su derecho a publicar contenido </h2>
              <p>
                {" "}
                Nuestro servicio le permite publicar contenido. Usted es
                responsable del Contenido que publica en el Servicio, incluida
                su legalidad, confiabilidad y adecuación.{" "}
              </p>
              <p>
                {" "}
                Al publicar Contenido en el Servicio, nos otorga el derecho y la
                licencia para usar, modificar, realizar públicamente, mostrar
                públicamente, reproducir y distribuir dicho Contenido en el
                Servicio y a través de él. Usted conserva todos y cada uno de
                sus derechos sobre cualquier Contenido que envíe, publique o
                muestre en el Servicio oa través de él y es responsable de
                proteger esos derechos. Usted acepta que esta licencia incluye
                el derecho de Nosotros a poner Su Contenido a disposición de
                otros usuarios del Servicio, quienes también pueden usar Su
                Contenido sujeto a estos Términos.{" "}
              </p>
              <p>
                {" "}
                Usted declara y garantiza que: (i) el Contenido es suyo (es de
                su propiedad) o que tiene derecho a usarlo y nos otorga los
                derechos y la licencia según lo dispuesto en estos Términos, y
                (ii) la publicación de Su El contenido en oa través del Servicio
                no viola los derechos de privacidad, derechos de publicidad,
                derechos de autor, derechos contractuales o cualquier otro
                derecho de cualquier persona.{" "}
              </p>
              <h2> Restricciones de contenido </h2>
              <p>
                {" "}
                La Compañía no es responsable por el contenido de los usuarios
                del Servicio. Usted comprende y acepta expresamente que es el
                único responsable del Contenido y de toda la actividad que se
                produzca en su cuenta, ya sea que lo haga usted o un tercero que
                utilice su cuenta.{" "}
              </p>
              <p>
                {" "}
                No puede transmitir ningún Contenido que sea ilegal, ofensivo,
                perturbador, que tenga la intención de repugnar, amenazar,
                calumniar, difamar, obsceno o de cualquier otro modo objetable.
                Los ejemplos de dicho Contenido objetable incluyen, entre otros,
                los siguientes:{" "}
              </p>
              <ul>
                <li>
                  {" "}
                  Actividades ilegales o que promueven actividades ilegales.{" "}
                </li>
                <li>
                  {" "}
                  Contenido difamatorio, discriminatorio o mezquino, incluidas
                  referencias o comentarios sobre religión, raza, orientación
                  sexual, género, origen nacional / étnico u otros grupos
                  objetivo.{" "}
                </li>
                <li>
                  {" "}
                  Spam, máquina, o generado aleatoriamente, que constituye
                  publicidad no autorizada o no solicitada, cartas en cadena,
                  cualquier otra forma de solicitud no autorizada o cualquier
                  forma de lotería o juego.{" "}
                </li>
                <li>
                  {" "}
                  Contener o instalar virus, gusanos, malware, troyanos u otro
                  contenido diseñado o destinado a interrumpir, dañar o limitar
                  el funcionamiento de cualquier software, hardware o equipo de
                  telecomunicaciones o para dañar u obtener acceso no autorizado
                  a cualquier datos u otra información de una tercera persona.{" "}
                </li>
                <li>
                  {" "}
                  Infringir los derechos de propiedad de cualquier parte,
                  incluida la patente, la marca comercial, el secreto comercial,
                  los derechos de autor, el derecho de publicidad u otros
                  derechos.{" "}
                </li>
                <li>
                  {" "}
                  Hacerse pasar por cualquier persona o entidad, incluida la
                  Compañía y sus empleados o representantes.{" "}
                </li>
                <li> Violar la privacidad de terceros. </li>
                <li> Información y características falsas. </li>
              </ul>
              <p>
                {" "}
                La Compañía se reserva el derecho, pero no la obligación, de, a
                su sola discreción, determinar si algún Contenido es apropiado y
                cumple con estos Términos, rechazar o eliminar este Contenido.
                Además, la Compañía se reserva el derecho de realizar cambios de
                formato, ediciones y cambios en la forma de cualquier Contenido.
                La Compañía también puede limitar o revocar el uso del Servicio
                si usted publica dicho Contenido objetable. Como la Compañía no
                puede controlar todo el contenido publicado por los usuarios y /
                o terceros en el Servicio, usted acepta usar el Servicio bajo su
                propio riesgo. Usted comprende que al usar el Servicio puede
                estar expuesto a contenido que puede encontrar ofensivo,
                indecente, incorrecto u objetable, y acepta que bajo ninguna
                circunstancia la Compañía será responsable de ninguna manera por
                ningún contenido, incluyendo cualquier error u omisión en
                cualquier contenido, o cualquier pérdida o daño de cualquier
                tipo incurrido como resultado de su uso de cualquier contenido.{" "}
              </p>
              <h2> Copias de seguridad de contenido </h2>
              <p>
                {" "}
                Aunque se realizan copias de seguridad periódicas del Contenido,
                la Compañía no garantiza que no habrá pérdida o corrupción de
                datos.{" "}
              </p>
              <p>
                {" "}
                Los puntos de copia de seguridad dañados o no válidos pueden
                deberse, entre otros, a Contenido que está dañado antes de
                realizar la copia de seguridad o que cambia durante el tiempo
                que se realiza una copia de seguridad.{" "}
              </p>
              <p>
                {" "}
                La Compañía brindará asistencia e intentará solucionar cualquier
                problema conocido o descubierto que pueda afectar las copias de
                seguridad del Contenido. Pero usted reconoce que la Compañía no
                tiene ninguna responsabilidad relacionada con la integridad del
                Contenido o la falla en restaurar exitosamente el Contenido a un
                estado utilizable.{" "}
              </p>
              <p>
                {" "}
                Acepta mantener una copia completa y precisa de cualquier
                Contenido en una ubicación independiente del Servicio.{" "}
              </p>
              <h1> Política de derechos de autor </h1>
              <h2> Infracción de propiedad intelectual </h2>
              <p>
                {" "}
                Respetamos los derechos de propiedad intelectual de otros.
                Nuestra política es responder a cualquier reclamo de que el
                Contenido publicado en el Servicio infringe los derechos de
                autor u otra infracción de propiedad intelectual de cualquier
                persona.{" "}
              </p>
              <p>
                {" "}
                Si es propietario de los derechos de autor, o está autorizado en
                nombre de uno, y cree que el trabajo protegido por derechos de
                autor se ha copiado de una manera que constituye una infracción
                de derechos de autor que se lleva a cabo a través del Servicio,
                debe enviar Su notificación por escrito a la atención de nuestro
                agente de derechos de autor por correo electrónico a
                bailo.app@gmail.com e incluya en Su aviso una descripción
                detallada de la presunta infracción.{" "}
              </p>
              <p>
                {" "}
                Es posible que usted sea responsable de los daños (incluidos los
                costos y los honorarios de los abogados) por tergiversar que
                cualquier Contenido infringe sus derechos de autor.{" "}
              </p>
              <h2>
                {" "}
                Aviso de DMCA y procedimiento de DMCA para reclamos de
                infracción de derechos de autor{" "}
              </h2>
              <p>
                {" "}
                Puede enviar una notificación de conformidad con la Ley de
                derechos de autor del milenio digital (DMCA) proporcionando a
                nuestro Agente de derechos de autor la siguiente información por
                escrito (consulte 17 U.S.C 512 (c) (3) para obtener más
                detalles):{" "}
              </p>
              <ul>
                <li>
                  {" "}
                  Una firma electrónica o física de la persona autorizada para
                  actuar en nombre del propietario del interés de los derechos
                  de autor.{" "}
                </li>
                <li>
                  {" "}
                  Una descripción del trabajo protegido por derechos de autor
                  que usted afirma que se ha infringido, incluida la URL (es
                  decir, la dirección de la página web) de la ubicación donde
                  existe el trabajo protegido por derechos de autor o una copia
                  del trabajo protegido por derechos de autor.{" "}
                </li>
                <li>
                  {" "}
                  Identificación de la URL u otra ubicación específica en el
                  Servicio donde se encuentra el material que usted afirma que
                  infringe.{" "}
                </li>
                <li>
                  {" "}
                  Su dirección, número de teléfono y dirección de correo
                  electrónico.{" "}
                </li>
                <li>
                  {" "}
                  Una declaración suya de que cree de buena fe que el uso en
                  disputa no está autorizado por el propietario de los derechos
                  de autor, su agente o la ley.{" "}
                </li>
                <li>
                  {" "}
                  Una declaración suya, hecha bajo pena de perjurio, de que la
                  información anterior en Su aviso es precisa y que usted es el
                  propietario de los derechos de autor o está autorizado para
                  actuar en nombre del propietario de los derechos de autor.{" "}
                </li>
              </ul>
              <p>
                {" "}
                Puede ponerse en contacto con nuestro agente de derechos de
                autor por correo electrónico a bailo.app@gmail.com. Al recibir
                una notificación, la Compañía tomará cualquier acción, a su
                entera discreción, que considere apropiada, incluida la
                eliminación del contenido impugnado del Servicio.{" "}
              </p>
              <h1> Propiedad intelectual </h1>
              <p>
                {" "}
                El Servicio y su contenido original (excluido el Contenido
                proporcionado por Usted u otros usuarios), las características y
                la funcionalidad son y seguirán siendo propiedad exclusiva de la
                Compañía y sus licenciantes.{" "}
              </p>
              <p>
                {" "}
                El Servicio está protegido por derechos de autor, marcas
                comerciales y otras leyes tanto del país como de otros países.{" "}
              </p>
              <p>
                {" "}
                Nuestras marcas comerciales e imagen comercial no se pueden
                utilizar en relación con ningún producto o servicio sin el
                consentimiento previo por escrito de la Compañía.{" "}
              </p>
              <h1> Sus comentarios para nosotros </h1>
              <p>
                {" "}
                Usted asigna todos los derechos, títulos e intereses sobre
                cualquier comentario que proporcione a la Compañía. Si por
                alguna razón dicha cesión es ineficaz, usted acepta otorgar a la
                Compañía un derecho y una licencia no exclusivos, perpetuos,
                irrevocables, libres de regalías y en todo el mundo para usar,
                reproducir, divulgar, sublicenciar, distribuir, modificar y
                explotar dichos Comentarios sin restricción.{" "}
              </p>
              <h1> Enlaces a otros sitios web </h1>
              <p>
                {" "}
                Nuestro Servicio puede contener enlaces a sitios web o servicios
                de terceros que no son propiedad ni están controlados por la
                Compañía.{" "}
              </p>
              <p>
                {" "}
                La Compañía no tiene control ni asume ninguna responsabilidad
                por el contenido, las políticas de privacidad o las prácticas de
                los sitios web o servicios de terceros. Además, reconoce y
                acepta que la Compañía no será responsable, directa o
                indirectamente, por ningún daño o pérdida causados ​​o
                presuntamente causado por o en conexión con el uso o dependencia
                de dicho contenido, bienes o servicios disponibles en oa través
                de dichos sitios web o servicios.{" "}
              </p>
              <p>
                {" "}
                Le recomendamos encarecidamente que lea los términos y
                condiciones y las políticas de privacidad de cualquier sitio web
                o servicio de terceros que visite.{" "}
              </p>
              <h1> Terminación </h1>
              <p>
                {" "}
                Podemos cancelar o suspender su cuenta de inmediato, sin previo
                aviso ni responsabilidad, por cualquier motivo, incluido, entre
                otros, si incumple estos términos y condiciones.{" "}
              </p>
              <p>
                {" "}
                Tras la rescisión, su derecho a utilizar el Servicio cesará
                inmediatamente. Si desea cancelar su cuenta, simplemente puede
                dejar de usar el servicio.{" "}
              </p>
              <h1> Limitación de responsabilidad </h1>
              <p>
                {" "}
                Independientemente de los daños en los que pueda incurrir, la
                responsabilidad total de la Compañía y cualquiera de sus
                proveedores bajo cualquier disposición de estos Términos y Su
                recurso exclusivo para todo lo anterior se limitará al monto
                realmente pagado por Usted a través del Servicio. o 100 USD si
                no ha comprado nada a través del Servicio.{" "}
              </p>
              <p>
                {" "}
                En la medida máxima permitida por la ley aplicable, en ningún
                caso la Compañía o sus proveedores serán responsables de ningún
                daño especial, incidental, indirecto o consecuente de ningún
                tipo (incluidos, entre otros, daños por lucro cesante, pérdida
                de datos u otra información, por interrupción del negocio, por
                lesiones personales, pérdida de privacidad que surja de o de
                alguna manera relacionada con el uso o la imposibilidad de usar
                el Servicio, software de terceros y / o hardware de terceros
                utilizado con el Servicio, o de otro modo en relación con
                cualquier disposición de estos Términos), incluso si la Compañía
                o cualquier proveedor han sido informados de la posibilidad de
                tales daños e incluso si el recurso no cumple con su propósito
                esencial.{" "}
              </p>
              <p>
                {" "}
                Algunos estados no permiten la exclusión de garantías implícitas
                o la limitación de responsabilidad por daños incidentales o
                consecuentes, lo que significa que algunas de las limitaciones
                anteriores pueden no aplicarse. En estos estados, la
                responsabilidad de cada parte estará limitada en la mayor medida
                permitida por la ley.{" "}
              </p>
              <h1>
                {" "}
                & quot; TAL CUAL & quot; y & quot; SEGÚN DISPONIBILIDAD & quot;
                Descargo de responsabilidad{" "}
              </h1>
              <p>
                {" "}
                El Servicio se le proporciona & quot; TAL CUAL & quot; y & quot;
                SEGÚN DISPONIBILIDAD & quot; y con todas las fallas y defectos
                sin garantía de ningún tipo. En la medida máxima permitida por
                la ley aplicable, la Compañía, en su propio nombre y en nombre
                de sus Afiliadas y sus respectivos licenciantes y proveedores de
                servicios, renuncia expresamente a todas las garantías, ya sean
                expresas, implícitas, estatutarias o de otro tipo, con respecto
                a la Servicio, incluidas todas las garantías implícitas de
                comerciabilidad, idoneidad para un propósito particular, título
                y no infracción, y garantías que puedan surgir del curso del
                trato, el curso del desempeño, el uso o la práctica comercial.
                Sin limitación a lo anterior, la Compañía no ofrece garantía ni
                compromiso, y no hace ninguna representación de ningún tipo de
                que el Servicio cumplirá con Sus requisitos, logrará los
                resultados previstos, será compatible o funcionará con cualquier
                otro software, aplicación, sistema o servicio, operará sin
                interrupciones, cumplir con los estándares de rendimiento o
                confiabilidad o estar libre de errores o que cualquier error o
                defecto pueda o será corregido.{" "}
              </p>
              <p>
                {" "}
                Sin limitar lo anterior, ni la Compañía ni ninguno de los
                proveedores de la compañía hace ninguna representación o
                garantía de ningún tipo, expresa o implícita: (i) en cuanto al
                funcionamiento o disponibilidad del Servicio, o la información,
                el contenido y materiales o productos incluidos en el mismo;
                (ii) que el Servicio será ininterrumpido o libre de errores;
                (iii) en cuanto a la precisión, confiabilidad o vigencia de
                cualquier información o contenido proporcionado a través del
                Servicio; o (iv) que el Servicio, sus servidores, el contenido o
                los correos electrónicos enviados desde o en nombre de la
                Compañía están libres de virus, scripts, troyanos, gusanos,
                malware, bombas de tiempo u otros componentes dañinos.{" "}
              </p>
              <p>
                {" "}
                Algunas jurisdicciones no permiten la exclusión de ciertos tipos
                de garantías o limitaciones sobre los derechos legales
                aplicables de un consumidor, por lo que es posible que algunas o
                todas las exclusiones y limitaciones anteriores no se apliquen a
                usted. Pero en tal caso, las exclusiones y limitaciones
                establecidas en esta sección se aplicarán en la mayor medida
                posible de conformidad con la ley aplicable.{" "}
              </p>
              <h1> Ley aplicable </h1>
              <p>
                {" "}
                Las leyes del País, excluyendo sus conflictos de leyes, regirán
                estos Términos y Su uso del Servicio. Su uso de la Aplicación
                también puede estar sujeto a otras leyes locales, estatales,
                nacionales o internacionales.{" "}
              </p>
              <h1> Resolución de disputas </h1>
              <p>
                {" "}
                Si tiene alguna inquietud o disputa sobre el Servicio, acepta
                primero intentar resolver la disputa de manera informal
                comunicándose con la Compañía.{" "}
              </p>
              <h1> Para usuarios de la Unión Europea (UE) </h1>
              <p>
                {" "}
                Si es un consumidor de la Unión Europea, se beneficiará de las
                disposiciones obligatorias de la ley del país en el que reside.{" "}
              </p>
              <h1> Cumplimiento legal de Estados Unidos </h1>
              <p>
                {" "}
                Usted declara y garantiza que (i) no se encuentra en un país que
                esté sujeto al embargo del gobierno de los Estados Unidos, o que
                haya sido designado por el gobierno de los Estados Unidos como &
                quot; apoyo a terroristas & quot; país, y (ii) no figura en
                ninguna lista del gobierno de los Estados Unidos de partes
                prohibidas o restringidas.{" "}
              </p>
              <h1> Divisibilidad y exención </h1>
              <h2> Divisibilidad </h2>
              <p>
                {" "}
                Si alguna disposición de estos Términos se considera inaplicable
                o inválida, dicha disposición se cambiará e interpretará para
                lograr los objetivos de dicha disposición en la mayor medida
                posible según la ley aplicable y las disposiciones restantes
                continuarán en pleno vigor y efecto. .{" "}
              </p>
              <h2> Renuncia </h2>
              <p>
                {" "}
                Salvo lo dispuesto en el presente, el hecho de no ejercer un
                derecho o exigir el cumplimiento de una obligación en virtud de
                estos Términos no afectará la capacidad de una de las partes
                para ejercer dicho derecho o requerir dicho cumplimiento en
                cualquier momento posterior ni será la renuncia a un
                incumplimiento constituye una renuncia a cualquier
                incumplimiento posterior.{" "}
              </p>
              <h1> Interpretación de traducción </h1>
              <p>
                {" "}
                Estos Términos y condiciones pueden haberse traducido si los
                hemos puesto a su disposición en nuestro Servicio. Acepta que el
                texto original en inglés prevalecerá en caso de disputa.{" "}
              </p>
              <h1> Cambios en estos Términos y condiciones </h1>
              <p>
                {" "}
                Nos reservamos el derecho, a Nuestro exclusivo criterio, de
                modificar o reemplazar estos Términos en cualquier momento. Si
                una revisión es material, haremos los esfuerzos razonables para
                proporcionar al menos 30 días & apos; aviso antes de que entren
                en vigencia los nuevos términos. Lo que constituye un cambio
                material se determinará a Nuestro exclusivo criterio.{" "}
              </p>
              <p>
                {" "}
                Al continuar accediendo o utilizando Nuestro Servicio después de
                que esas revisiones entren en vigencia, usted acepta estar
                sujeto a los términos revisados. Si no está de acuerdo con los
                nuevos términos, en su totalidad o en parte, deje de usar el
                sitio web y el Servicio.{" "}
              </p>
              <h1> Contáctenos </h1>
              <p>
                {" "}
                Si tiene alguna pregunta sobre estos Términos y condiciones,
                puede contactarnos:{" "}
              </p>
              <ul>
                <li> Por correo electrónico: bailo.app@gmail.com </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Aux>
    );
  }
}

export default TermsOfUseEsp;
