/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
import React, { Component } from "react";

export default class PodcastAudioPlay extends Component {
  // state = {
  //   play: false,
  // };

  render() {
    return (
      <div className="spotify-embed">
        <iframe          
          src={`https://open.spotify.com/embed-podcast/episode/${this.props.audioPlay}`}
          title="Spotify player"
          frameBorder="0"
          allow="encrypted-media"
          height="150"
          allowtransparency="true"
        ></iframe>
      </div>
    );
  }
}
