import styled, { keyframes } from "styled-components";

const rotation = keyframes`
from{
  transform: rotate(0deg);
}

to{
  transform: rotate(360deg);
}
`;

const Loading = styled.div`
  z-index: 99999;
  margin: 2px;
  padding: 2px;
  position: absolute;
  top: 50%;
  left: 50%;

  height: 3.5em;
  width: 3.5em;
  border: 1px solid #c870c8;
  border-radius: 50%;
  border-top: none;
  border-right: none;
  margin: 20em auto;
  background: transparent;
  animation: ${rotation} 1s linear infinite;
`;

export default Loading;
