/* eslint-disable no-console */
import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { Row, Col, Card, Collapse } from "react-bootstrap";
// import { Link } from "react-router-dom";

import Loading from "../../components/Loading";
import Text from "../../components/Text";
// import Breadcrumb from "../../components/Breadcrumb";

import Aux from "../../hoc/_Aux/Aux";

const { listOfQA } = require("../../helpers/listOfQA");

class KnowledgeBase extends Component {
  state = {
    loading: true,
    isBasic: false,
    isMultiTarget: [],
    accordionKey: 0,
  };

  async componentDidMount() {
    try {
      this.setState({
        loading: false,
      });
    } catch (error) {
      console.log(error);
      this.setState({
        loading: false,
      });
    }
  }

  render() {
    const { loading } = this.state;
    // const { dictionary } = this.props.lang;
    // const { user } = this.props.auth;
    const { accordionKey } = this.state;

    return (
      <Aux>
        {/* <Breadcrumb /> */}
        {loading && <Loading />}
        {!loading && (
          <Row>
            <Col sm={12} className="accordion">
              <h5>
                <Text tid="commonQuestions" />
              </h5>
              <hr />
              {listOfQA.map((oneQuestion) => (
                <Card className="mt-2" key={oneQuestion.id}>
                  <Card.Header>
                    <Card.Title as="h5">
                      <a
                        key={oneQuestion.id}
                        onClick={() => this.setState({ accordionKey: (accordionKey !== oneQuestion.id) ? oneQuestion.id : 0 })}
                        aria-controls="accordion1"
                        aria-expanded={accordionKey === oneQuestion.id}
                      >
                        <span style={{ 'marginRight': "25px", 'background': '#04a9f5', 'paddingRight': "5px", 'paddingLeft': "5px" }}>
                          {accordionKey === oneQuestion.id ? "-" : "+"}
                        </span>
                        {oneQuestion[this.props.lang.language.id].title}
                      </a>
                    </Card.Title>
                  </Card.Header>
                  <Collapse in={this.state.accordionKey === oneQuestion.id} key={oneQuestion.id}>
                    <div id="accordion1" key={oneQuestion.id}>
                      <Card.Body>
                        <Card.Text key={oneQuestion.id}>
                          {oneQuestion[this.props.lang.language.id].text}
                        </Card.Text>
                      </Card.Body>
                    </div>
                  </Collapse>
                </Card>
              ))
              }
            </Col>
          </Row>
        )}
      </Aux>
    );
  }
}

KnowledgeBase.propTypes = {
  auth: PropTypes.object.isRequired,
  lang: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  lang: state.lang,
});

export default connect(mapStateToProps)(KnowledgeBase);
