/* eslint-disable import/named */
/* eslint-disable no-console */
/* eslint-disable class-methods-use-this */
import React, { Component } from "react";
import { connect } from "react-redux";
import { GoogleLogin } from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { PropTypes } from "prop-types";
// import { Link } from "react-router-dom";
import classnames from "classnames";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ReactGA from "react-ga";

import { NavLink } from "react-router-dom";
import "../../styles/scss/style.scss";
import Aux from "../../hoc/_Aux/Aux";
import Text from "../../components/Text";
// import Breadcrumb from "../../components/Breadcrumb";

import {
  loginUser,
  googleLoginUser,
  facebookLoginUser,
} from "../../store/actions/authActions";

ReactGA.initialize(process.env.REACT_APP_GA_ID_TRACKING);

class Login extends Component {
  state = {
    username: "",
    password: "",
    isPasswordVisible: false,
    errors: {},
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleFormSubmit = (e) => {
    e.preventDefault();
    const user = {
      username: this.state.username,
      password: this.state.password,
    };

    this.props.loginUser(user /* this.props.auth.history */);
  };

  responseGoogle = async (response) => {
    const tokenId = response?.tokenId;

    this.props.googleLoginUser(tokenId);
  };

  googleFailure = (error) => {
    console.error(`Error ${error}`);
    toast.error("😧 Google Login was unsuccessful. Try again");
  };

  responseFacebook = (response) => {
    const newUser = {
      _id: response?.id,
      tokenId: response?.accessToken,
      username: response?.name,
      email: response?.email,
      picture: response?.picture.data.url,
      email_verified: true,
    };

    this.props.facebookLoginUser(newUser);
  };

  // eslint-disable-next-line react/no-deprecated
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    // eslint-disable-next-line react/prop-types
    if (nextProps.errors) {
      // eslint-disable-next-line react/prop-types
      this.setState({ errors: nextProps.errors });
      toast.error("😧 ERROR!");
    }
  }

  componentWillUnmount() {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  render() {
    const { username, password, errors } = this.state;

    return (
      <Aux>
        {/* <Breadcrumb /> */}
        <div className="auth-wrapper">
          <div className="auth-content">
            <div className="auth-bg">
              <span className="r" />
              <span className="r s" />
              <span className="r s" />
              <span className="r" />
            </div>
            <div className="card">
              <div className="card-body text-center">
                <div className="mb-4">
                  <i className="feather icon-unlock auth-icon" />
                </div>
                <h3 className="mb-4">
                  <Text tid="login" />
                </h3>
                <form onSubmit={this.handleFormSubmit}>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      name="username"
                      placeholder="username or email"
                      // className="form-control"
                      className={classnames("form-control", {
                        "is-invalid": errors.username,
                      })}
                      id="username"
                      value={username}
                      onChange={this.handleChange}
                    />
                    {errors && (
                      <div className="invalid-feedback">{errors.username}</div>
                    )}
                  </div>
                  <div className="input-group mb-4">
                    <input
                      type={!this.state.isPasswordVisible ? "password" : "text"}
                      name="password"
                      autoComplete="off"
                      placeholder="password"
                      // className="form-control"
                      className={classnames("form-control", {
                        "is-invalid": errors.password,
                      })}
                      id="password"
                      value={password}
                      onChange={this.handleChange}
                    />
                    <button
                      type="button"
                      className="toggleButton"
                      onClick={() =>
                        this.setState({
                          isPasswordVisible: !this.state.isPasswordVisible,
                        })
                      }
                    >
                      {this.state.isPasswordVisible ? (
                        <i className="fa fa-eye"></i>
                      ) : (
                        <i className="fa fa-eye-slash"></i>
                      )}
                    </button>
                    {errors && (
                      <div className="invalid-feedback">{errors.password}</div>
                    )}
                  </div>

                  {/* <div className="form-group text-left">
                    <div className="checkbox checkbox-fill d-inline">
                      <input type="checkbox" name="checkbox-fill-1" id="checkbox-fill-a1" />
                      <label htmlFor="checkbox-fill-a1" className="cr"> Save credentials</label>
                    </div>
                  </div> */}
                  <div className="d-flow col">
                    <button
                      disabled={!(this.state.username && this.state.password)}
                      type="submit"
                      value="Login"
                      className="btn btn-primary shadow-2 mb-4"
                    >
                      <Text tid="login" />
                    </button>
                  </div>
                </form>
                <div className="d-flow col">
                  <GoogleLogin
                    clientId={process.env.REACT_APP_GOOGLE_LOGIN}
                    render={(renderProps) => (
                      <button
                        className="btn btn-primary shadow-2 mb-4"
                        onClick={renderProps.onClick}
                        disabled={renderProps.disabled}
                        variant="contained"
                      >
                        <i className="fa fa-google" aria-hidden="true" />
                        <Text tid="googleLogin" />
                      </button>
                    )}
                    onSuccess={this.responseGoogle}
                    onFailure={this.googleFailure}
                    cookiePolicy={"single_host_origin"}
                  />
                  <FacebookLogin
                    appId={process.env.REACT_APP_FACEBOOK_APPID}
                    autoLoad={false}
                    fields="name,email,picture"
                    callback={this.responseFacebook}
                    render={(renderProps) => (
                      <button
                        className="btn btn-primary shadow-2 mb-4"
                        onClick={renderProps.onClick}
                      >
                        <i className="fa fa-facebook" aria-hidden="true" />
                        <Text tid="facebookLogin" />
                      </button>
                    )}
                  />
                </div>

                {/* <p className="mb-2 text-muted">Forgot password? <NavLink to="/auth/reset-password-1">Reset</NavLink></p> */}
                <p className="mb-0 text-muted">
                  <Text tid="keenTo" />{" "}
                  <NavLink to="/signup">
                    <Text tid="signupCap" />
                  </NavLink>
                  ?
                </p>
                <p className="mb-0 text-muted">
                  <Text tid="backTo" />{" "}
                  <NavLink to="/">
                    <Text tid="homepage" />
                  </NavLink>
                </p>
                {/* mirar diferencia entre mb-0 y mb-2 --- es por el espacio que da hacia abajo */}
              </div>
            </div>
          </div>
        </div>
      </Aux>
    );
  }
}

Login.propTypes = {
  googleLoginUser: PropTypes.func.isRequired,
  facebookLoginUser: PropTypes.func.isRequired,
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  loginUser,
  googleLoginUser,
  facebookLoginUser,
})(Login);
