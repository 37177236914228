/* eslint-disable no-console */
import React, { Component } from "react";
import { NavLink } from 'react-router-dom';

import ShowBlog from "./ShowBlog";
import Loading from "../../components/Loading"
import NavLanding from "../../components/NavLanding"
import Aux from "../../hoc/_Aux/Aux";
import '../../styles/scss/style.scss';
// import Breadcrumb from "../../components/Breadcrumb";

import { Styles } from "../landing/LandingStyles";

import postService from "../../services/postService";

class Blog extends Component {
  state = {
    profile: {
      ptitle: "",
      name: "",
      avtar: "",
      profileurl: "",
    },
    item: [],
    isloading: true,
    error: null
  };

  async componentDidMount() {
    await postService.getMediumFeed()
      .then((data) => {
        const avatar = data.feed.image;
        const profileLink = data.feed.link;

        const { title } = data.feed;

        this.setState(
          (pre) => ({
            profile: {
              ...pre.profile,
              ptitle: title,
              profileurl: profileLink,
              avtar: avatar,

            },
            item: data.items,
            isloading: false
          }),
          () => {
            // console.log(this.state);
          }
        );
        // console.log(data, res);
      })
      .catch((e) => {
        this.setState({ error: e.toJSON() })
        console.log(e);
      });
  }

  render() {
    let post

    if (this.state.item) {
      post = this.state.item.map((onePost, index) => (
        <ShowBlog key={index} {...onePost} {...this.state.profile} {...index} />
      ))
    }
    if (this.state.isloading) {
      post = <Loading />
    }
    if (this.state.error) {
      const error = this.state.error.code ? this.state.error.code : this.state.error.name;
      const errorMsg = this.state.error.message;
      post = (
        <>
          <h2 className="red center1">{error}</h2>
          <p className="errorMessage center1">{errorMsg}</p>
        </>
      );
    }

    return (
      <Aux>
        <Styles>
          <div className="card-body">
            <div className="container">
              {post.length !== 0 ? (
                <div>
                  <NavLanding />
                  <div
                    style={{ paddingTop: '100px' }}
                  >
                    <div className="row">
                      {post}
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  style={{ paddingTop: '0px' }}
                >
                  <NavLanding />
                  <div className="auth-wrapper offline">
                    <div className="text-center">
                      <h1 className="mb-4">Coming Soon</h1>
                      <h5 className="text-muted mb-4">Our site is under construction</h5>
                      <NavLink to="/" className="btn btn-primary mb-4"><i className="feather icon-home" />Back to Home</NavLink>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Styles>
      </Aux>
    );
  }
}

export default Blog;